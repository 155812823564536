import { Injectable } from '@angular/core'
import { Action, Selector, State, StateContext, Store } from '@ngxs/store'
import { tap } from 'rxjs/operators'
import { WeeklyTargetStateModel } from './weekly-target.model'
import { TargetsService } from '../targets.service'
import {
  GetAvailableWeeksForWeeklyTarget,
  GetShopsTargets,
  GetShopsTargetsComps,
  GetTargetsHistory
} from './weekly-target.actions'
import { sortBy, uniqBy } from 'lodash'
import { ShopsState } from '@lla-platform/shops/shops-data-access'
import { IWeekTargetResponse } from '../../interfaces/weekly-target.interface'
import { ITargetsHistoryInfo } from '../../interfaces/targets-history.interface'
import { IGenericObject } from '@lla-platform/core/core-data-access'

@State<WeeklyTargetStateModel>({
  name: 'weeklyTarget'
})
@Injectable()
export class WeeklyTargetState {
  constructor(
    private TargetsService: TargetsService,
    private store: Store
  ) {}

  @Selector()
  static availableWeeks(state: WeeklyTargetStateModel) {
    return state.availableWeeks ?? []
  }

  @Selector()
  static weeklyTargets(state: WeeklyTargetStateModel): IWeekTargetResponse | undefined {
    return state.weeklyTargets
      ? {
          currentWeek: state.weeklyTargets?.currentWeek,
          lastYearWeek: state.weeklyTargets?.lastYearWeek,
          locations: state.weeklyTargets?.locations?.map((el) => ({
            ...el,
            items: el.items?.map((item) => ({
              ...item,
              locationName: el.location?.name,
              locationType: el.location?.locationType
            }))
          }))
        }
      : undefined
  }

  @Selector()
  static allRegions(state: WeeklyTargetStateModel) {
    const data = state.weeklyTargets?.locations ?? []
    return [
      { label: 'Organization', value: 'all' },
      ...sortBy(
        uniqBy(
          data
            .map((el) => ({
              label: el.locationInfo?.region ?? '',
              value: el.locationInfo?.region ?? ''
            }))
            .filter((el) => el.value !== ''),
          (x) => x.value
        ),
        (x) => x.label
      )
    ]
  }

  @Selector()
  static allDivisions(state: WeeklyTargetStateModel) {
    const data = state.weeklyTargets?.locations ?? []
    return [
      { label: 'All', value: 'all' },
      ...sortBy(
        uniqBy(
          data
            .map((el) => ({
              label: el.locationInfo?.division ?? '',
              value: el.locationInfo?.division ?? ''
            }))
            .filter((el) => el.value !== ''),
          (x) => x.value
        ),
        (x) => x.label
      )
    ]
  }

  @Selector()
  static targetsHistory(state: WeeklyTargetStateModel): ITargetsHistoryInfo | undefined {
    if (!state?.targetsHistory) {
      return
    }
    const { targetsHistory } = state
    const allPeriods = targetsHistory.totals.map((el) => el.period)
    const totals: IGenericObject = Object.fromEntries(targetsHistory.totals.map((el) => [el.period, el]))
    const locations: IGenericObject[] = []

    for (const location of targetsHistory.locations) {
      locations.push({
        locationId: location.locationId,
        locationName: location.locationName,
        ...Object.fromEntries(location.periods.map((el) => [el.period, el]))
      })
    }

    const graphs: IGenericObject[] = [
      {
        stateLabel: 'Number of locations in "Missed"',
        state: 0,
        ...Object.fromEntries(targetsHistory.graphs.map((el) => [el.period, el.missed]))
      },
      {
        stateLabel: 'Number of locations in "Tire 1"',
        state: 1,
        ...Object.fromEntries(targetsHistory.graphs.map((el) => [el.period, el.tier1]))
      },
      {
        stateLabel: 'Number of locations in "Tire 2"',
        state: 2,
        ...Object.fromEntries(targetsHistory.graphs.map((el) => [el.period, el.tier2]))
      },
      {
        stateLabel: 'Number of locations in "Tire 3"',
        state: 3,
        ...Object.fromEntries(targetsHistory.graphs.map((el) => [el.period, el.tier3]))
      }
    ]

    return {
      graphs,
      locations,
      totals,
      allPeriods
    }
  }

  @Action(GetAvailableWeeksForWeeklyTarget)
  getAvailableWeeksForWeeklyTarget(ctx: StateContext<WeeklyTargetStateModel>) {
    const availableWeeks = ctx.getState()?.availableWeeks
    if (availableWeeks) {
      return availableWeeks
    }

    return this.TargetsService.getAvailableWeeks().pipe(
      tap((res) => {
        ctx.patchState({
          availableWeeks: res?.availableWeeks ?? []
        })
      })
    )
  }

  @Action(GetShopsTargets)
  getShopsTargets(ctx: StateContext<WeeklyTargetStateModel>, { weekNumber }: GetShopsTargets) {
    ctx.patchState({
      weeklyTargets: undefined
    })
    return this.TargetsService.getShopsTargets(weekNumber).pipe(
      tap((res) => {
        const allShops = this.store.selectSnapshot(ShopsState.allShops)
        ctx.patchState({
          weeklyTargets: {
            currentWeek: res?.currentWeek,
            lastYearWeek: res?.lastYearWeek,
            locations: (res?.locations ?? []).map((el) => ({
              ...el,
              locationInfo: allShops.find((s) => s.id === el.location?.id)
            }))
          }
        })
      })
    )
  }

  @Action(GetShopsTargetsComps)
  getShopsTargetsComps(ctx: StateContext<WeeklyTargetStateModel>, { weekNumber }: GetShopsTargetsComps) {
    ctx.patchState({
      weeklyTargets: undefined
    })
    return this.TargetsService.getShopsTargetsComps(weekNumber).pipe(
      tap((res) => {
        const allShops = this.store.selectSnapshot(ShopsState.allShops)
        ctx.patchState({
          weeklyTargets: {
            currentWeek: res?.currentWeek,
            lastYearWeek: res?.lastYearWeek,
            locations: (res?.locations ?? []).map((el) => ({
              ...el,
              locationInfo: allShops.find((s) => s.id === el.location?.id)
            }))
          }
        })
      })
    )
  }

  @Action(GetTargetsHistory)
  getTargetsHistory(ctx: StateContext<WeeklyTargetStateModel>, { payload }: GetTargetsHistory) {
    ctx.patchState({
      targetsHistory: undefined
    })
    return this.TargetsService.getTargetsHistory(payload).pipe(
      tap((res) => {
        ctx.patchState({
          targetsHistory: res
        })
      })
    )
  }
}

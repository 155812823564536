import { ITimePeriod } from '@lla-platform/core/core-data-access'

export interface IContactsTypeWidgetTotal {
  total: number
  newCustomers: number
  newCustomersPercent: number
  newCustomersPercentFormmatted: string
  friendsCustomers: number
  friendsCustomersPercent: number
  friendsCustomersPercentFormatted: string
  advocatesCustomers: number
  advocatesCustomersPercent: number
  advocatesCustomersPercentFormatted: string
}

export interface IContactsTypeWidgetRegion {
  group: string
  total?: IContactsTypeWidgetTotal
}

export interface IContactsTypeWidgetResponse {
  period: ITimePeriod
  total: IContactsTypeWidgetTotal
  groups: IContactsTypeWidgetRegion[]
}

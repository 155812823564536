<div class="{{ containerClass }} border border-gray-300 rounded-sm flex items-stretch">
  <div
    *ngFor="let item of buttons"
    (click)="selectedButtonValue = item.value; selectedButtonChanged.emit(item.value)"
    class="{{ itemClass }}
    flex items-center justify-center text-txt border-r border-gray-300 last-of-type:border-r-0 cursor-pointer last-of-type:rounded-r-sm first-of-type:rounded-l-sm
    py-xs px-lg sm:px-base text-xs leading-xs sm:text-sm sm:leading-sm"
    [ngClass]="{
      'bg-primary text-white': selectedButtonValue === item.value,
      'hover:bg-ui-hover': selectedButtonValue !== item.value
    }"
  >
    {{ item.label }}
  </div>
</div>

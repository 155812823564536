import { ApplicationRoutes } from '../../../app-routes-names'

export const EmployeeRoutes = {
  list: 'list',
  laborSummary: 'labor-summary',
  technicianSummary: 'technician-summary',
  technicianList: 'technician-list'
}

const { employee } = ApplicationRoutes

export const EmployeePaths = {
  list: `/${employee}/${EmployeeRoutes.list}`,
  laborSummary: `/${employee}/${EmployeeRoutes.laborSummary}`,
  technicianSummary: `/${employee}/${EmployeeRoutes.technicianSummary}`,
  technicianList: `/${employee}/${EmployeeRoutes.technicianList}`
}

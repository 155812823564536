import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import {
  FiltersState,
  FilterStateKeys,
  INew_TableAction,
  INew_TableFIlterNotifier,
  New_TableColumnType,
  PeriodType,
  PeriodTypeToLabel,
  SetFilters
} from '@lla-platform/core/core-data-access'
import { New_TableOperatorToSentence } from '../../table-filter.constant'
import { Store } from '@ngxs/store'
import { Observable } from 'rxjs'
import { MatDialog } from '@angular/material/dialog'
import { ConfirmSimpleModeDialogComponent } from '../confirm-simple-mode-dialog/confirm-simple-mode-dialog.component'
import moment from 'moment'

interface IAppliedTableFilter {
  key: string
  text?: string
}

@UntilDestroy()
@Component({
  selector: 'lla-table-filters-list',
  templateUrl: './table-filters-list.component.html',
  styleUrls: ['./table-filters-list.component.scss'],
  standalone: false
})
export class TableFiltersListComponent implements OnInit {
  @Input() advancedFilterKey: FilterStateKeys
  @Input() tableActions: INew_TableAction
  @Input() showExport = true
  @Output() filterChanged = new EventEmitter<INew_TableFIlterNotifier>()
  @Output() clearAllFilters = new EventEmitter()

  isAdvancedMode: boolean

  filters: IAppliedTableFilter[] = []
  hasFilters = false
  dateFormat = 'MMM DD, YYYY'

  constructor(
    private store: Store,
    private dialogService: MatDialog
  ) {}

  ngOnInit(): void {
    if (this.advancedFilterKey) {
      const isAdvancedMode$: Observable<boolean> = this.store.select(
        (FiltersState as any)[this.advancedFilterKey]
      )

      isAdvancedMode$.pipe(untilDestroyed(this)).subscribe((res) => {
        this.isAdvancedMode = res
      })
    }
    if (this.tableActions.filters) {
      this.filters = Object.values(this.tableActions.filters)?.map((el) => {
        const texts = el.items?.map((item) => {
          let text: string
          switch (el.columnType) {
            case New_TableColumnType.date:
              if (item.value === PeriodType.Custom) {
                text = `from ${moment(item.extraValue.from).utc().format(this.dateFormat)} to ${moment(item.extraValue.to).utc().format(this.dateFormat)}`
              } else {
                text = `${New_TableOperatorToSentence[item.operator]} ${PeriodTypeToLabel[item.value]}`
              }
              break

            case New_TableColumnType.checkbox:
              text = `${New_TableOperatorToSentence[item.operator]} ${
                item.value && el.showCountThreshold && item.value.length > el.showCountThreshold
                  ? item.value.length + ' ' + el.showCountLabel
                  : item.value
                      ?.map((x: string) => el.listItems?.find((i) => i.value === x)?.label ?? x)
                      .join(', ')
              }`
              break

            case New_TableColumnType.radio:
              text = `${New_TableOperatorToSentence[item.operator]} ${
                el.listItems?.find((i) => i.value === item.value)?.label ?? item.value
              }`
              break

            default:
              text = `${New_TableOperatorToSentence[item.operator]} ${item.value}`
              break
          }
          return text
        })

        return {
          key: el.key,
          text:
            texts && texts.length > 0
              ? `${el.label} ${texts?.join(' ' + el.aggregator + ' ')}`
              : undefined
        }
      })
      this.hasFilters = !!this.filters.find((el) => el.text)
    }
  }

  removeFilter(key: string) {
    if (!this.tableActions.filters) {
      return
    }
    const filter = this.tableActions.filters[key]

    this.filterChanged.emit({
      filter: {
        ...filter,
        items: []
      }
    })
  }

  onFilterChanged(key: string, event: INew_TableFIlterNotifier) {
    this.filterChanged.emit({
      ...event,
      key
    })
  }

  setAdvancedMode() {
    if (!this.advancedFilterKey) {
      return
    }
    if (this.isAdvancedMode) {
      this.dialogService
        .open(ConfirmSimpleModeDialogComponent, {
          autoFocus: false,
          panelClass: 'normal-modal'
        })
        .afterClosed()
        .pipe(untilDestroyed(this))
        .subscribe((res) => {
          if (res) {
            this.clearAllFilters.emit()
            this.store.dispatch(
              new SetFilters({
                [this.advancedFilterKey]: false
              })
            )
          }
        })
    } else {
      this.store.dispatch(
        new SetFilters({
          [this.advancedFilterKey]: true
        })
      )
    }
  }
}

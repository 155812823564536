import { IAdvancedTable, IPaginationFilteredResponse } from '@lla-platform/core/core-data-access'
import { EmployeeRole } from '../enums/employee-role'
import { PaymentType } from '../enums/payment-type'

export interface IEmployeeDetail {
  id: string
  firstName: string
  lastName: string
  role: EmployeeRole
  paymentType: PaymentType
  mainLocationId: string
  mainLocationName: string
  includedInOT: boolean
}

export interface IEmployeeUpdateRequest extends IEmployeeDetail {
  employeeId: string
}

export interface IEmployeeListItem extends IAdvancedTable, IEmployeeDetail {}

export interface IEmployeeListResponse extends IPaginationFilteredResponse {
  employees: IEmployeeListItem[]
}

import { ITimePeriod } from '@lla-platform/core/core-data-access'

export interface ITireWidgetTotal {
  total: number
  stockablesPercent: number
  stockablesPercentFormatted: string
  notStockablesPercent: number
  notStockablesPercentFormatted: string
  stockablesCount: number
  notStockablesCount: number
}

export interface ITireWidgetRegion {
  group: string
  total?: ITireWidgetTotal
}

export interface ITireWidgetResponse {
  period: ITimePeriod
  total: ITireWidgetTotal
  groups: ITireWidgetRegion[]
}

import { Component, Input, OnInit } from '@angular/core'
import {
  ControlContainer,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup
} from '@angular/forms'
import { ISelectItem } from '@lla-platform/core/core-ui'
import { IShopLocationItem, ShopsState } from '@lla-platform/shops/shops-data-access'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { Store } from '@ngxs/store'
import { Dictionary, isEqual, sortBy } from 'lodash'

export interface ITableHeaderSortInfo {
  columnName: string
  title?: string
}

@UntilDestroy()
@Component({
  selector: 'lla-shops-selector',
  templateUrl: './shops-selector.component.html',
  styleUrls: ['./shops-selector.component.scss'],
  standalone: false
})
export class ShopsSelectorComponent implements OnInit {
  @Input() controlName: string
  @Input() selectedGroup?: string
  @Input() titleExtraClass = ''
  @Input() showTitle = true
  @Input() searchInputId: string

  formControl: UntypedFormControl
  statesList: string[] = []
  allShopsList: ISelectItem[] = []
  filterdShopList: ISelectItem[] = []
  Math = Math
  userShopsGroupByStates: Dictionary<IShopLocationItem[]>

  form: UntypedFormGroup

  constructor(
    private store: Store,
    private controlContainer: ControlContainer,
    private fb: UntypedFormBuilder
  ) {}

  ngOnInit(): void {
    this.formControl = this.controlContainer.control?.get(this.controlName) as UntypedFormControl
    this.allShopsList = this.store.selectSnapshot<ISelectItem[]>(ShopsState.userShopsAsList)
    this.statesList = this.store.selectSnapshot<string[]>(ShopsState.states)

    this.userShopsGroupByStates = this.store.selectSnapshot(ShopsState.userShopsGroupByStates)
    this.setSelectedGroup()

    this.formControl?.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
      this.setSelectedGroup()
    })

    this.form = this.fb.group({
      searchText: [null]
    })

    this.form
      .get('searchText')
      ?.valueChanges.pipe(untilDestroyed(this))
      .subscribe((value: string) => {
        this.filterItems(value)
      })

    this.filterItems()
  }

  selectedGroupChanged(item: string) {
    this.selectedGroup = item
    const selectedShopsId = this.allShopsList
      .filter((el) => el?.extraInfo?.state === item)
      .map((el) => el.value)
    this.formControl?.setValue(selectedShopsId)
  }

  setSelectedGroup() {
    const sortedLocationIds = sortBy(this.formControl?.value)
    if (sortedLocationIds && sortedLocationIds.length > 0) {
      this.selectedGroup = Object.keys(this.userShopsGroupByStates).find((state) => {
        const sortedStateShopsIds = sortBy(this.userShopsGroupByStates[state]?.map((el) => el.id))
        return isEqual(sortedLocationIds, sortedStateShopsIds)
      })
    } else {
      this.selectedGroup = undefined
    }
  }

  filterItems(value?: string) {
    const trimValue = value?.toLowerCase().trim() ?? ''
    if (trimValue === '') {
      this.filterdShopList = [...this.allShopsList]
    } else {
      this.filterdShopList = this.allShopsList.filter((el) => el.label.toLowerCase().includes(trimValue))
    }
  }

  allFilterdShopsSelected() {
    const locationIds = this.formControl?.value
    return locationIds && locationIds.length > 0 && locationIds.length === this.filterdShopList.length
  }

  selectAllFilterdShops() {
    this.selectedGroup = undefined
    const locationIds = this.formControl?.value
    if (locationIds && locationIds.length === this.filterdShopList.length) {
      this.formControl?.reset()
      return
    }
    this.formControl?.setValue(this.filterdShopList.map((el) => el.value))
  }
}

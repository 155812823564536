import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core'
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms'
import {
  PeriodType,
  PeriodTypeButtonsInfo,
  PeriodTypeSelectItem
} from '@lla-platform/core/core-data-access'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'

@UntilDestroy()
@Component({
  selector: 'lla-time-period-filter',
  templateUrl: './time-period-filter.component.html',
  styleUrls: ['./time-period-filter.component.scss'],
  standalone: false
})
export class TimePeriodFilterComponent implements OnInit, OnChanges {
  @Input() selectedPeriod: PeriodType
  @Output() periodChanged = new EventEmitter<PeriodType>()

  periodTypeButtonsInfo = PeriodTypeButtonsInfo
  periodTypeSelectItem = PeriodTypeSelectItem
  form: UntypedFormGroup

  constructor(private fb: UntypedFormBuilder) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      selectedPeriod: [this.selectedPeriod]
    })

    this.form
      .get('selectedPeriod')
      ?.valueChanges.pipe(untilDestroyed(this))
      .subscribe((value) => {
        if (value !== null && value !== undefined) {
          this.selectedPeriod = value
          this.periodChanged.emit(value)
        }
      })
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.form?.patchValue(
      {
        selectedPeriod: changes['selectedPeriod']?.currentValue
      },
      { emitEvent: false }
    )
  }
}

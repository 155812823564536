<div class="flex flex-col table-filter-modal-container relative" style="max-height: 90vh">
  <!-- header -->
  <div class="p-base pb-lg">
    <!-- Close Btn -->
    <div class="absolute cursor-pointer top-base right-lg flex text-txt">
      <svg-icon key="close" class="!text-2xs" (click)="dialogRef.close()"></svg-icon>
    </div>

    <!-- Title -->
    <div class="text-base leading-base text-txt font-semibold">Filters</div>
    <div class="mt-2xs text-sm leading-sm text-txt">Locations</div>
  </div>

  <!-- Form -->
  <form [formGroup]="form" class="px-base pb-xl flex-1 overflow-y-auto flex flex-col beautyScroll">
    <lla-shops-selector
      controlName="locationIds"
      [showTitle]="false"
      searchInputId="searchInputId"
    ></lla-shops-selector>
  </form>

  <!-- Footer -->
  <div class="mx-base py-base flex items-center justify-between">
    <div class="text-base leading-base text-error cursor-pointer" (click)="resetForm()">Clear all</div>
    <lla-button
      variant="ghost"
      size="medium"
      extraBtnClass="!py-0 !text-primary"
      [disabled]="form.invalid"
      (click)="onSubmit()"
    >
      Show results
    </lla-button>
  </div>
</div>

import { Component, EventEmitter, Input, Output } from '@angular/core'
import { UntilDestroy } from '@ngneat/until-destroy'

@UntilDestroy()
@Component({
  selector: 'lla-checkbox-element',
  templateUrl: './checkbox-element.component.html',
  styleUrls: ['./checkbox-element.component.scss'],
  standalone: false
})
export class CheckboxElementComponent {
  @Input() value: boolean
  @Input() extraClass = ''
  @Input() extraLabelClass = ''
  @Input() label = ''
  @Input() disabled = false
  @Input() size = 14

  @Output() changed = new EventEmitter<boolean>()
}

import { ISelectItem } from '@lla-platform/core/core-ui'

export enum DashbordGroupBy {
  Location = 'Location',
  Region = 'Region'
}

export const DashbordGroupByToLabel: { [key: string]: string } = {
  Location: 'Location',
  Region: 'Region'
}

export const DashbordGroupByItems: ISelectItem[] = [
  {
    label: DashbordGroupByToLabel[DashbordGroupBy.Location],
    value: DashbordGroupBy.Location
  },
  {
    label: DashbordGroupByToLabel[DashbordGroupBy.Region],
    value: DashbordGroupBy.Region
  }
]

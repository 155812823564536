import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { ITireWidgetResponse } from './interfaces/dashboard-tires.interface'
import { DASHBOARD_API_URL } from './dashboard-api-routes'
import { HttpService } from '@lla-platform/core/core-data-access'
import { DateService } from '@lla-platform/core/core-util'
import { IContactsTypeWidgetResponse } from './interfaces/dashboard-contacts-type.interface'
import {
  IWeeklyTargetWidgetRequest,
  IWeeklyTargetWidgetResponse
} from './interfaces/dashboard-weekly-target.interface'
import { IArReceivablesWidgetResponse } from './interfaces/dashboard-ar-receivables.interface'
import { IReceivablesWidgetResponse } from './interfaces/dashboard-receivables.interface'
import {
  IDashboardWidgetRequest,
  IDashboardWidgetTimeRequest
} from './interfaces/dashboard-widget.interface'

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  constructor(
    private http: HttpService,
    private dateService: DateService
  ) {}

  getTireWidgetInfo(payload: IDashboardWidgetTimeRequest): Observable<ITireWidgetResponse> {
    const { from, to } = payload
    return this.http.post<ITireWidgetResponse>(DASHBOARD_API_URL.getTireWidgetInfo, {
      ...payload,
      from: this.dateService.momentToDateString(from),
      to: this.dateService.momentToDateString(to)
    })
  }

  getWeeklyTargetWidgetInfo(
    payload: IWeeklyTargetWidgetRequest
  ): Observable<IWeeklyTargetWidgetResponse> {
    return this.http.post<IWeeklyTargetWidgetResponse>(
      DASHBOARD_API_URL.getWeeklyTargetWidgetInfo,
      payload
    )
  }

  getContactsTypeWidgetInfo(
    payload: IDashboardWidgetTimeRequest
  ): Observable<IContactsTypeWidgetResponse> {
    const { from, to } = payload
    return this.http.post<IContactsTypeWidgetResponse>(DASHBOARD_API_URL.getContactsTypeWidgetInfo, {
      ...payload,
      from: this.dateService.momentToDateString(from),
      to: this.dateService.momentToDateString(to)
    })
  }

  getArReceivablesWidgetInfo(
    payload: IDashboardWidgetTimeRequest
  ): Observable<IArReceivablesWidgetResponse> {
    const { from, to } = payload
    return this.http.post<IArReceivablesWidgetResponse>(DASHBOARD_API_URL.getArReceivablesWidgetInfo, {
      ...payload,
      from: this.dateService.momentToDateString(from),
      to: this.dateService.momentToDateString(to)
    })
  }

  getReceivablesWidgetInfo(payload: IDashboardWidgetRequest): Observable<IReceivablesWidgetResponse> {
    return this.http.post<IReceivablesWidgetResponse>(
      DASHBOARD_API_URL.getReceivablesWidgetInfo,
      payload
    )
  }
}

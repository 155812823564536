import { IShopInfo, ITimePeriod, IUrlFilterItem } from '@lla-platform/core/core-data-access'
import { EmployeeRole } from 'employee-data-access'

export interface IAttendanceDay {
  workingDay: Date
  workedMinutes: number
  workedMinutesFormated: string
  isValid: boolean
  workedAway: boolean
}

export interface IAttendanceItem {
  employeeId: string
  onSite: boolean
  name: string
  days?: IAttendanceDay[]
  totalMinutes: number
  total: string
  overTimeMinutes: number
  overTime: string
  includedInOvertime: boolean
  forecastMinutes: number
  forecastTotal: string
  forecastOverTimeMinutes: number
  forecastOverTime: string
  role: EmployeeRole

  queryParams?: IUrlFilterItem
}

export interface IAttendanceInfo extends IShopInfo {
  employees: IAttendanceItem[]
  totalOvertime: number
  sales: number
  percentOfTier1Sales: number
  tier1Sales: number
  effectiveLaborRate: number
  totalOvertimeFormatted: string
  salesFormatted: string
  percentOfTier1Formatted: string
  effectiveLaborRateFormatted: string

  peopleOnSite: number
  alerts: number
}

export interface IAttendanceResponse {
  currentWeek: ITimePeriod
  locations: IAttendanceInfo[]
}

export interface IEmplyeeAttendanceItem {
  attendanceId?: string
  clockIn?: string
  clockOut?: string
  total?: string
  totalMinutes?: number
  note?: string
  errorMesage?: string
  isValid?: boolean
  locationName?: string
  timeZone?: string
}

export interface IEmplyeeAttendanceDetailReponse {
  employeeName: string
  locationName: string
  attendances: IEmplyeeAttendanceItem[]
  grandTotal: string
}

<div
  class="w-full p-base pb-2xl flex-col border-r border-ui-separator overflow-auto beautyScroll"
  [ngClass]="{ 'hidden lg:flex h-full !w-[320px]': forDesktop, 'h-[100dvh]': !forDesktop }"
>
  <!-- Main Items -->
  <div>
    <ng-container *ngFor="let item of mains">
      <ng-container *ngTemplateOutlet="expandableMenuItem; context: { item: item }"></ng-container>
    </ng-container>
  </div>

  <!-- Service Items -->
  <div>
    <ng-container *ngFor="let item of services">
      <ng-container *ngTemplateOutlet="expandableMenuItem; context: { item: item }"></ng-container>
    </ng-container>
  </div>

  <!-- Bookmarks Items -->
  <div *ngIf="bookmarks && bookmarks.length > 0" class="group-header">
    <span>Bookmarks</span>
  </div>
  <div>
    <ng-container *ngFor="let item of bookmarks">
      <ng-container *ngTemplateOutlet="expandableMenuItem; context: { item: item }"></ng-container>
    </ng-container>
  </div>
</div>

<!-- Expandable Menu Item Template -->
<ng-template #expandableMenuItem let-item="item">
  <div *ngIf="!item.hidden">
    <div (click)="changeCollapsableMenuStatus(item)">
      <ng-container *ngTemplateOutlet="menuItem; context: { item: item }"></ng-container>
    </div>
    <ng-container *ngIf="isSubMenuVisible(item)">
      <ng-container *ngFor="let subMenu of item.subMenus">
        <ng-container
          *ngTemplateOutlet="menuItem; context: { item: subMenu, isSubMenuItem: true }"
        ></ng-container>
      </ng-container>
    </ng-container>
  </div>
</ng-template>

<!-- Item Template -->
<ng-template #menuItem let-item="item" let-isSubMenuItem="isSubMenuItem">
  <a
    *ngIf="!item.hidden"
    class="menu-item group"
    [ngClass]="{ '!text-sm !leading-sm': isSubMenuItem }"
    [routerLink]="item.path"
    [queryParams]="item.queryParams"
    [routerLinkActive]="item.type === 'bookmark' ? '' : 'active-menu-item'"
    [routerLinkActiveOptions]="{ exact: !!item.exact }"
    [target]="item.type === 'bookmark' ? '_blank' : '_self'"
    (click)="onMenuItemClick(item)"
  >
    <div class="flex">
      <svg-icon
        [key]="item.icon"
        [fontSize]="isSubMenuItem ? '8px' : '15px'"
        [ngClass]="{ 'ml-[10px]': isSubMenuItem }"
      ></svg-icon>
    </div>
    <span class="flex-1" [ngClass]="{ 'pl-base': !isSubMenuItem, 'pl-[10px]': isSubMenuItem }">
      {{ item.label }}
    </span>
    <div *ngIf="item.subMenus" class="pl-base flex text-txt-disabled">
      <svg-icon
        [key]="expandedIndexes.includes(item.index) ? 'minus' : 'plus'"
        class="!text-2xs"
      ></svg-icon>
    </div>
    <div
      (click)="deleteBookmark($event, item.extraInfo)"
      *ngIf="item.type === 'bookmark' && !item.subMenus"
      class="pl-base -mr-[3px] hidden group-hover:flex items-center"
    >
      <div class="flex text-txt-secondary">
        <svg-icon
          *ngIf="deletingBookmarkId !== item.extraInfo"
          key="close-circle"
          class="!text-sm"
        ></svg-icon>
      </div>
      <lla-loading-spinner *ngIf="deletingBookmarkId === item.extraInfo"></lla-loading-spinner>
    </div>
  </a>
</ng-template>

import { environment } from '@lla-platform/core/core-util'

export const TECHNICIAN_API_URL = {
  laborSummary: `${environment.apiUrl}/labor/labor-summary-by-location`,
  exportLaborSummary: `${environment.apiUrl}/labor/labor-summary-by-location/export`,
  technicianSummary: `${environment.apiUrl}/technician/technician-summary-by-location`,
  exportTechnicianSummary: `${environment.apiUrl}/technician/technician-summary-by-location/export`,
  technicianList: `${environment.apiUrl}/technician/filters`,
  exportTechnicianList: `${environment.apiUrl}/technician/filters/export`
}

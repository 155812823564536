import { ISelectItem } from '@lla-platform/core/core-ui'

export enum PaymentType {
  NotSet = 'NotSet',
  Salary = 'Salary',
  StraightHourly = 'StraightHourly',
  LLATechPay = 'LLATechPay',
  FlatRate = 'FlatRate',
  PercentOfLabor = 'PercentOfLabor',
  StraightPlusPercent = 'StraightPlusPercent',
  SalaryPlusPercent = 'SalaryPlusPercent'
}

export const PaymentTypeToLabel: { [key: string]: string } = {
  NotSet: 'Not Set',
  Salary: 'Salary',
  StraightHourly: 'Straight Hourly',
  LLATechPay: 'LLA Tech Pay',
  FlatRate: 'Flat Rate',
  PercentOfLabor: 'Percent of Labor',
  StraightPlusPercent: 'Straight plus Percent',
  SalaryPlusPercent: 'Salary plus Percent'
}

export const PaymentTypeItems: ISelectItem[] = [
  {
    label: PaymentTypeToLabel[PaymentType.NotSet],
    value: PaymentType.NotSet
  },
  {
    label: PaymentTypeToLabel[PaymentType.Salary],
    value: PaymentType.Salary
  },
  {
    label: PaymentTypeToLabel[PaymentType.StraightHourly],
    value: PaymentType.StraightHourly
  },
  {
    label: PaymentTypeToLabel[PaymentType.LLATechPay],
    value: PaymentType.LLATechPay
  },
  {
    label: PaymentTypeToLabel[PaymentType.FlatRate],
    value: PaymentType.FlatRate
  },
  {
    label: PaymentTypeToLabel[PaymentType.PercentOfLabor],
    value: PaymentType.PercentOfLabor
  },
  {
    label: PaymentTypeToLabel[PaymentType.StraightPlusPercent],
    value: PaymentType.StraightPlusPercent
  },
  {
    label: PaymentTypeToLabel[PaymentType.SalaryPlusPercent],
    value: PaymentType.SalaryPlusPercent
  }
]

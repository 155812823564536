import { Injectable } from '@angular/core'

import { cloneDeep, isArray } from 'lodash'
import { DateService, environment } from '@lla-platform/core/core-util'
import { FilterStateKeys, PeriodType, SetFilters } from '@lla-platform/core/core-data-access'
import {
  New_AggregatorType,
  INew_PaginationFilteredRequest,
  INew_TableAction,
  INew_TableFIlterNotifier,
  INew_TableFilterRule,
  New_TableColumnType,
  New_TableFilters,
  New_TableOperator
} from '../interfaces/table-filter.interface'
import { Store } from '@ngxs/store'
import moment from 'moment'

@Injectable({
  providedIn: 'root'
})
export class TableActionsService {
  constructor(
    private dateService: DateService,
    private store: Store
  ) {}

  createRequestPayload(info?: INew_TableAction): INew_PaginationFilteredRequest {
    const paylad: INew_PaginationFilteredRequest = {
      filters: {},
      top: environment.pageSize,
      skip: ((info?.page ?? 1) - 1) * environment.pageSize
    }

    if (info?.sort && info.sort.active) {
      paylad['orderBy'] = [
        {
          direction: info.sort.direction === 'asc' ? 'Ascending' : 'Descending',
          property: info.sort.active
        }
      ]
    }

    paylad.filters = Object.fromEntries(
      Object.keys(info?.filters ?? [])
        .map((el) => info?.filters?.[el])
        .filter((el) => el?.items && el.items.length > 0)
        .map((el) => {
          if (!el) {
            return []
          }
          let aggregator = el.aggregator
          let items: INew_TableFilterRule[] | undefined
          switch (el.columnType) {
            case New_TableColumnType.checkbox:
              if (isArray(el.items?.[0].value)) {
                items = (el.items[0].value ?? []).map((value: string) => ({
                  operator: New_TableOperator.Equals,
                  value
                }))
              } else {
                items = []
              }
              break

            case New_TableColumnType.date:
              aggregator = New_AggregatorType.And
              items = el.items?.map((el) => ({
                operator: el.operator,
                value: el.value ?? PeriodType.Custom,
                extraValue: {
                  from: moment(el.extraValue.from, 'YYYY-MM-DD', true).isValid()
                    ? el.extraValue.from
                    : this.dateService.momentToDateString(el.extraValue.from),
                  to: moment(el.extraValue.to, 'YYYY-MM-DD', true).isValid()
                    ? el.extraValue.to
                    : this.dateService.momentToDateString(el.extraValue.to)
                }
              }))
              break

            default:
              items = el.items?.map((el) => ({
                operator: el.operator,
                value: `${el.value}`
              }))
              break
          }
          return [
            el.key,
            {
              key: el.key,
              aggregator,
              items
            }
          ]
        })
    )

    return paylad
  }

  prepareChangedFilters(payload: {
    key?: string
    info?: INew_TableFIlterNotifier
    tableActions: INew_TableAction
    tableFilters: New_TableFilters
  }): INew_TableAction | undefined {
    if (!payload.key && !payload.info) {
      return this.clearAllFilters({
        tableActions: payload.tableActions,
        tableFilters: payload.tableFilters
      })
    }

    const { key, info, tableActions, tableFilters } = payload
    if (!info || !key || key === '') {
      return
    }
    if (info.shouldDelete) {
      tableFilters[key].items = undefined
      tableFilters[key].aggregator = undefined
    }
    if (info.filter) {
      tableFilters[key] = { ...info.filter }
    }

    if (info.sort !== undefined) {
      tableActions.sort = info.sort
    }
    if (!info.sort) {
      tableActions.page = 1
    }
    return { ...tableActions, filters: tableFilters }
  }

  clearAllFilters(payload: {
    tableActions: INew_TableAction
    tableFilters: New_TableFilters
  }): INew_TableAction | undefined {
    const { tableActions, tableFilters } = payload
    Object.keys(tableFilters).forEach((key) => {
      const filter = tableFilters[key]
      let shouldReset = false
      if (filter.columnType === New_TableColumnType.number && filter.items && filter.items.length > 1) {
        shouldReset = true
      } else if (
        filter.columnType === New_TableColumnType.text &&
        filter.items &&
        (filter.items.length > 1 ||
          (filter.items.length === 1 && filter.items[0].operator !== New_TableOperator.StartsWith))
      ) {
        shouldReset = true
      }
      if (shouldReset) {
        tableFilters[key].items = undefined
        tableFilters[key].aggregator = undefined
      }
    })
    tableActions.page = 1
    return { ...tableActions, filters: tableFilters }
  }

  saveFilters(tableActions: INew_TableAction, key: FilterStateKeys) {
    const saveTableAction = cloneDeep(tableActions)
    if (saveTableAction.filters) {
      for (const key in saveTableAction.filters) {
        const filter = saveTableAction.filters[key]
        saveTableAction.filters[key] = { ...filter, listItems: undefined }
      }
    }
    this.store.dispatch(new SetFilters({ [key]: saveTableAction }))
  }
}

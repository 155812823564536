import { INew_TableAction } from '@lla-platform/core/core-data-access'
import { IEmployeeUpdateRequest } from '../../interfaces/employee-list.interface'

export class GetEmployee {
  static readonly type = '[employee] Get Employee'
  constructor(public id: string) {}
}

export class DeleteEmployee {
  static readonly type = '[employee] Delete Employee'
  constructor(public id: string) {}
}

export class UpdateEmployee {
  static readonly type = '[employee] Update Employee'
  constructor(public payload: Partial<IEmployeeUpdateRequest>) {}
}

export class GetEmployeeList {
  static readonly type = '[employee] Get Employee List'
  constructor(public info: INew_TableAction) {}
}

import {
  IAdvancedTable,
  INew_TableAction,
  IPaginationFilteredResponse,
  ITimePeriodRequest
} from '@lla-platform/core/core-data-access'
import { EmployeeRole } from 'employee-data-access'

export interface ITechnicianListRequest extends INew_TableAction, ITimePeriodRequest {}

export interface ITechnicianListItem extends IAdvancedTable {
  index?: number
  employeeId?: string
  firstName: string
  lastName?: string
  role?: EmployeeRole
  locationId?: string
  locationName?: string
  laborHours: number
  technicianHours: number
  invoicedLabor: number
  invoicedLaborFormatted: string
  technicianEfficiency: number
  technicianEfficiencyFormatted: string
  clockHours: number
  clockHoursFormatted: string
  laborHoursFormatted: string
  technicianHoursFormatted: string
}

export interface ITechnicianListResponse extends IPaginationFilteredResponse {
  technicians: ITechnicianListItem[]
  totalTechnicianHours: number
  totalLaborHours: number
  totalClockHours: number
  totalInvoicedLabor: number
  totalTechnicianEfficiency: number
  totalInvoicedLaborFormatted: string
  totalTechnicianEfficiencyFormatted: string
  totalLaborHoursFormatted: string
  totalTechnicianHoursFormatted: string
  totalClockHoursFormatted: string
}

export class SetSchedulerData {
  static readonly type = '[scheduler] Set Scheduler Data'
  constructor(
    public workDayDate: Date | string,
    public templateIndex: number,
    public userIds: string[]
  ) {}
}

export class CopySchedulerData {
  static readonly type = '[scheduler] Copy Scheduler Data'
  constructor(public dayIndex: number) {}
}

import { Injectable } from '@angular/core'
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http'
import { Observable } from 'rxjs'
import { tap } from 'rxjs/operators'
import { USERS_API_URL } from '@lla-platform/users/users-data-access'
import { ShowErrorService } from '../services/show-error.service'
import { ToastrService } from 'ngx-toastr'

interface IAllowedUrl {
  url: string
  methoud: 'get' | 'post' | 'put' | 'delete'
}

/**
 * This http interceptor handle server error
 */
@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private showErrorService: ShowErrorService,
    private toastrService: ToastrService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const allowedUrl: IAllowedUrl[] = [{ url: USERS_API_URL.me, methoud: 'get' }]
    return next.handle(request).pipe(
      tap({
        error: (err: any) => {
          if (
            allowedUrl.find(
              (el) => request.url.includes(el.url) && request.method.toLowerCase() === el.methoud
            )
          ) {
            return
          }

          if (err?.error?.redirectUrl) {
            window.location.href = err.error.redirectUrl
            this.toastrService.info(
              'You are not logged in to Google account! You will redirect to Google automatically'
            )
            return
          }

          this.showErrorService.showHttpError(err)

          if (err.status === 401) {
            window.location.href = '/login'
          }
        }
      })
    )
  }
}

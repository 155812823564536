<div
  class="flex items-center py-sm mt-sm bg-white rounded-t-sm {{ extraClass }}"
  [ngClass]="{
    'rounded-b-sm': !expanded,
    'cursor-pointer': fullClickable
  }"
  (click)="expandable && fullClickable ? toggleCollapse.emit() : undefined"
>
  <div class="flex-1 flex items-center">
    @if (indexNumber === undefined) {
      <div class="pl-sm pr-sm flex items-center justify-center text-txt-secondary">
        <svg-icon [key]="icon" class="!text-sm"></svg-icon>
      </div>
    } @else {
      <div class="ml-sm mr-sm w-[25px] text-right text-base leading-base font-semibold">
        {{ indexNumber + 1 }}.
      </div>
    }
    <div class="pr-base text-txt font-semibold text-base leading-base">
      {{ label }}
    </div>
    <div
      *ngIf="expandable"
      class="flex text-gray-400 hover:text-txt cursor-pointer"
      (click)="!fullClickable ? toggleCollapse.emit() : undefined"
    >
      <svg-icon [key]="expanded ? 'minus' : 'plus'" class="!text-xs"></svg-icon>
    </div>
  </div>

  <ng-content></ng-content>
</div>

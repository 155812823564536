import { ITimePeriod, ITimePeriodRequest, IUrlFilters } from '@lla-platform/core/core-data-access'

export interface ITechnicianSummaryRequest extends ITimePeriodRequest {
  locationIds?: string[]
}

// Labor Summary
export interface ILaborSummaryItem extends IUrlFilters {
  locationId?: string
  locationName?: string
  laborHours: number
  backClockHours: number
  frontClockHours: number
  invoicedLabor: number
  sales: number
  backLaborEfficiency: number
  frontLaborEfficiency: number
  effectiveLaborRate: number
  invoicedLaborFormatted: string
  salesFormatted: string
  backLaborEfficiencyFormatted: string
  frontLaborEfficiencyFormatted: string
  effectiveLaborRateFormatted: string
  backClockHoursFormatted: string
  frontClockHoursFormatted: string
  laborHoursFormatted: string
}

export interface ILaborSummaryResponse {
  timePeriod: ITimePeriod
  locations: ILaborSummaryItem[]
  totals: ILaborSummaryItem
  averages: ILaborSummaryItem
}

// Technician Summary

export interface ITechnicianSummaryItem extends IUrlFilters {
  locationId?: string
  locationName?: string
  technicianHours: number
  laborHours: number
  invoicedLabor: number
  clockHours: number
  technicianEfficiency: number
  technicianHoursFormatted: string
  laborHoursFormatted: string
  invoicedLaborFormatted: string
  clockHoursFormatted: string
  technicianEfficiencyFormatted: string
}

export interface ITechnicianSummaryResponse {
  timePeriod: ITimePeriod
  locations: ITechnicianSummaryItem[]
  totals: ITechnicianSummaryItem
  averages: ITechnicianSummaryItem
}

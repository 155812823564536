import { Component, EventEmitter, Input, Output } from '@angular/core'
import { UntilDestroy } from '@ngneat/until-destroy'
import { IShopExtractedInfo } from './google-place-input.interface'

@UntilDestroy()
@Component({
  selector: 'lla-google-place-input',
  templateUrl: './google-place-input.component.html',
  styleUrls: ['./google-place-input.component.scss'],
  standalone: false
})
export class GooglePlaceInputComponent {
  @Input() controlName: string
  @Input() label = ''
  @Input() loading = false
  @Output() addressChanged = new EventEmitter<IShopExtractedInfo>()

  addressComponents: google.maps.GeocoderAddressComponent[]

  handleAddressChange(address: google.maps.places.PlaceResult) {
    if (!address) {
      return
    }
    this.addressComponents = address.address_components ?? []
    this.addressChanged.emit({
      name: address.name,
      address: address.formatted_address,
      city: this.extractAddressPart('locality'),
      postCode: this.extractAddressPart('postal_code'),
      stateAbbreviation: this.extractAddressPart('administrative_area_level_1', 'short'),
      phoneNumber: address.formatted_phone_number,
      websiteUrl: address.website,
      lat: address.geometry?.location?.lat(),
      long: address.geometry?.location?.lng()
    })
  }

  extractAddressPart(type: string, nameType: 'short' | 'long' = 'long') {
    const addressComponent = this.addressComponents?.find((el) => el.types.includes(type))
    if (!addressComponent) {
      return
    }
    switch (nameType) {
      case 'short':
        return addressComponent.short_name

      default:
        return addressComponent.long_name
    }
  }
}

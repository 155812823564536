import { Injectable } from '@angular/core'
import { HttpService, INew_TableAction, TableActionsService } from '@lla-platform/core/core-data-access'
import { Observable } from 'rxjs'
import { ADMIN_API_URL } from './admin-api-routes'
import { IAdminShopTiersResponse, IAdminShopTiersUpdate } from '../interfaces/admin-shop-tires.interface'
import { Permission } from '@lla-platform/users/users-data-access'
import {
  IAdminUpdatePermissionsRequest,
  IAdminUserPermissionsResponse,
  IBulkEditLocationsRequest,
  IBulkEditPermissionsRequest
} from '../interfaces/admin-user-permissions.interface'
import { IAdminUsersListResponse } from '../interfaces/admin-users-list.interface'

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  constructor(
    private http: HttpService,
    private tableActionsService: TableActionsService
  ) {}

  getShopTires(): Observable<IAdminShopTiersResponse> {
    return this.http.get(ADMIN_API_URL.targets)
  }

  updateShopTires(payload: IAdminShopTiersUpdate) {
    return this.http.post(ADMIN_API_URL.targets, payload)
  }

  getUsersList(info: INew_TableAction): Observable<IAdminUsersListResponse> {
    return this.http.post(ADMIN_API_URL.usersList, this.tableActionsService.createRequestPayload(info))
  }

  getUserPermissions(userId: string): Observable<IAdminUserPermissionsResponse> {
    return this.http.get(ADMIN_API_URL.userPermissions(userId))
  }

  updateUserPermissions(userId: string, payload: IAdminUpdatePermissionsRequest) {
    return this.http.post(ADMIN_API_URL.userPermissions(userId), payload)
  }

  getAllPermissions(): Observable<Permission[]> {
    return this.http.get(ADMIN_API_URL.userPermissions())
  }

  bulkEditLocations(payload: IBulkEditLocationsRequest) {
    return this.http.post(ADMIN_API_URL.bulkEditLocations, payload)
  }

  bulkEditPermissions(payload: IBulkEditPermissionsRequest) {
    return this.http.post(ADMIN_API_URL.bulkEditPermissions, payload)
  }
}

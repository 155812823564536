import { ITechnicianListRequest } from '../../interfaces/technician-list.interface'
import { ITechnicianSummaryRequest } from '../../interfaces/technician-summary.interface'

export class GetLaborSummary {
  static readonly type = '[technician] Get Labor Summary'
  constructor(public payload: ITechnicianSummaryRequest) {}
}

export class GetTechnicianSummary {
  static readonly type = '[technician] Get Technician Summary'
  constructor(public payload: ITechnicianSummaryRequest) {}
}

export class GetTechnicianList {
  static readonly type = '[technician] Get Technician List'
  constructor(public info: ITechnicianListRequest) {}
}

import { Component, Input } from '@angular/core'

export type MgBtnSize = 'medium' | 'medium-shrink' | 'small' | 'small-shrink'

@Component({
  selector: 'lla-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  standalone: false
})
export class ButtonComponent {
  @Input() variant: 'primary' | 'secondary' | 'error' | 'black' | 'black-invert' | 'ghost' = 'primary'
  @Input() size: MgBtnSize = 'small'
  @Input() type: 'button' | 'menu' | 'reset' | 'submit' = 'button'
  @Input() disabled = false
  @Input() loading = false
  @Input() loadingExtraClass = ''
  @Input() iconType: 'matIcon' | 'iconPath' | 'svgIcon' = 'svgIcon'
  @Input() icon: string
  @Input() iconPosition: 'left' | 'right' = 'left'
  @Input() iconClass = '!text-sm'
  @Input() extraBtnClass = ''
}

import { IDashboardWidgetRequest } from './dashboard-widget.interface'

export interface IWeeklyTargetWidgetRequest extends IDashboardWidgetRequest {
  weekNumber: number
}

export interface IWeeklyTargetWidgetTotal {
  salesFormmated: string
  salesPercent: number
  salesPercentFormatted: string
  carBonusFormatted: string
  carBonusPercent: number
  carBonusPercentFormatted: string
  tiresFormmated: string
  tiresPercent: number
  tiresPercentFormatted: string

  salesTierFormmated: string
  carBonusTierFormatted: string
  tiresTierFormmated: string
}

export interface IWeeklyTargetWidgetRegion {
  group: string
  targets?: IWeeklyTargetWidgetTotal
}

export interface IWeeklyTargetWidgetResponse {
  total: IWeeklyTargetWidgetTotal
  groups: IWeeklyTargetWidgetRegion[]
}

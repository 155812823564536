export interface IReceivablesWidgetTotal {
  over60_90: number
  over60_90Formatted: string
  over90_Plus: number
  over90_PlusFormatted: string
}

export interface IReceivablesWidgetRegion {
  group: string
  total?: IReceivablesWidgetTotal
}

export interface IReceivablesWidgetResponse {
  total: IReceivablesWidgetTotal
  groups: IReceivablesWidgetRegion[]
}

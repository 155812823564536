import { Injectable } from '@angular/core'
import {
  HttpService,
  IExportFileResponse,
  INew_TableAction,
  TableActionsService
} from '@lla-platform/core/core-data-access'
import { Observable } from 'rxjs'
import {
  IEmployeeDetail,
  IEmployeeListResponse,
  IEmployeeUpdateRequest
} from '../interfaces/employee-list.interface'
import { EMPLOYEE_API_URL } from './employee-api-routes'

@Injectable({
  providedIn: 'root'
})
export class EmployeeService {
  constructor(
    private http: HttpService,
    private tableActionsService: TableActionsService
  ) {}

  getEmployee(id: string): Observable<IEmployeeDetail> {
    return this.http.get(EMPLOYEE_API_URL.getEmployee(id))
  }

  deleteEmployee(id: string) {
    return this.http.delete(EMPLOYEE_API_URL.deleteEmployee(id))
  }

  updateEmployee(payload: Partial<IEmployeeUpdateRequest>) {
    return this.http.post(EMPLOYEE_API_URL.updateEmployee, payload)
  }

  getEmployeeList(info: INew_TableAction): Observable<IEmployeeListResponse> {
    return this.http.post(
      EMPLOYEE_API_URL.employeeList,
      this.tableActionsService.createRequestPayload(info)
    )
  }

  exportEmployeeList(info: INew_TableAction): Observable<IExportFileResponse> {
    return this.http.post(
      EMPLOYEE_API_URL.exportEmployeeList,
      this.tableActionsService.createRequestPayload(info)
    )
  }
}

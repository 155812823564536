import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'

@Component({
  selector: 'lla-no-report-banner',
  templateUrl: './no-report-banner.component.html',
  styleUrls: ['./no-report-banner.component.scss'],
  standalone: false
})
export class NoReportBannerComponent implements OnInit {
  @Input() containerClass = ''
  @Input() info?: { title?: string; subTitle?: string }
  @Input() imagePath: string
  @Input() variant: 'normal' | 'error' | 'error-border' = 'normal'
  @Input() alwaysMobileView = false
  @Output() refreshClicked = new EventEmitter()

  ngOnInit(): void {
    this.imagePath = 'assets/images/waves'
    if (this.variant === 'error' || this.variant === 'error-border') {
      this.imagePath = 'assets/images/waves-error'
    }
  }
}

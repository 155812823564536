import { Component, EventEmitter, Input, Output } from '@angular/core'

@Component({
  selector: 'lla-filter-button',
  templateUrl: './filter-button.component.html',
  styleUrls: ['./filter-button.component.scss'],
  standalone: false
})
export class FilterButtonComponent {
  @Input() numberOfFilters?: number
  @Output() filterButtonClicked = new EventEmitter()
}

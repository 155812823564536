import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core'
import { UntilDestroy } from '@ngneat/until-destroy'
import noUiSlider, { target } from 'nouislider'

@UntilDestroy()
@Component({
  selector: 'lla-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
  standalone: false
})
export class SliderComponent implements OnInit, OnChanges, OnDestroy {
  @Input() min = 0
  @Input() max = 100
  @Input() value: number[]
  @Input() step: number | undefined = undefined
  @Input() tooltips: string[] | undefined = undefined

  @Output() minUpdated = new EventEmitter<number>()
  @Output() maxUpdated = new EventEmitter<number>()
  @Output() allUpdated = new EventEmitter<number[]>()

  slider: target

  ngOnInit(): void {
    this.slider = document.getElementById('slider') as target

    noUiSlider.create(this.slider, {
      start: this.value && this.value.length === 2 ? this.value : [this.min, this.max],
      connect: true,
      range: {
        min: this.min,
        max: this.max
      },
      step: this.step,
      tooltips: this.tooltips
        ? {
            to: (x: number) => {
              if (this.tooltips) {
                return this.tooltips[Math.round(x)]
              }
              return ''
            }
          }
        : undefined
    })

    this.slider.noUiSlider?.on('update', (value) => {
      if (!value || value.length < 2) {
        return
      }
      this.minUpdated.emit(Number(value[0]))
      this.maxUpdated.emit(Number(value[1]))
      this.allUpdated.emit([Number(value[0]), Number(value[1])])
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    const value = changes['value']
    if (value?.currentValue) {
      this.slider?.noUiSlider?.set(value.currentValue)
    }
  }

  ngOnDestroy(): void {
    this.slider?.noUiSlider?.off('update')
    this.slider?.noUiSlider?.destroy()
    this.slider?.remove()
  }
}

<div
  class="{{ extraClass }}"
  [ngClass]="{ 'p-sm': !contentSlot.children.length, 'cursor-pointer': !filter.disable }"
>
  <div #contentSlot (click)="!filter.disable ? filterMenuTrigger.openMenu() : undefined">
    <ng-content></ng-content>
  </div>
  <div
    *ngIf="!contentSlot.children.length"
    class="min-w-max flex items-center text-sm leading-sm text-txt"
    [ngClass]="{
      '!text-primary': filter && filter.items && filter.items.length > 0,
      'justify-end': rightAlign,
      'justify-center': centerAlign
    }"
    (click)="!filter.disable ? filterMenuTrigger.openMenu() : undefined"
  >
    <span>
      {{ filter.label }}
    </span>
    <div
      *ngIf="filter.infoTitle"
      [ngxTippy]="infoTooltipTemplate"
      [tippyProps]="tooltipOption"
      class="hidden md:flex ml-[6px] text-primary"
    >
      <svg-icon key="info-circle" class="!text-xs"></svg-icon>
    </div>
    <div
      *ngIf="sortDirection"
      class="ml-[6px] flex text-txt-secondary"
      [ngClass]="{ 'rotate-180': sortDirection === 'desc' }"
    >
      <svg-icon *ngIf="sortDirection" key="arrow-up-full" class="!text-2xs"></svg-icon>
    </div>
  </div>
  <div
    [matMenuTriggerFor]="filterMenu"
    #filterMenuTrigger="matMenuTrigger"
    (onMenuOpen)="focusOnInput()"
  ></div>
</div>

<mat-menu
  #filterMenu="matMenu"
  [hasBackdrop]="true"
  backdropClass="mg-menu-backdrop"
  class="lla-filter-menu"
  (closed)="onMenuClose()"
>
  <div [id]="filter.key + 'filtertop'"></div>
  <div
    class="relative p-base pb-0 flex flex-col w-[320px] beautyScroll"
    [ngClass]="{
      'md:!w-[460px]': filter.columnType === tableColumnTypes.date && !onlySort,
      'md:!w-[370px]': filter.columnType === tableColumnTypes.number && !onlySort,
      'md:!w-[700px] 2xl:!w-[1000px] 2xl:!max-h-[700px]': filter.shopSelector,
      '!max-h-[450px]': filter.columnType === tableColumnTypes.checkbox && !onlySort,
      '!w-max': onlySort
    }"
    (click)="$event.stopPropagation()"
    (keydown.Tab)="$event.stopPropagation()"
  >
    <div *ngIf="!filter?.hideSorting" class="flex items-center" [ngClass]="{ 'pb-base': onlySort }">
      <div *ngIf="onlySort" class="flex-1 text-sm leading-sm text-txt mr-base">{{ filter.label }}</div>
      <div
        class="sort-btn mr-sm"
        [ngClass]="{ 'sort-btn-active': sortDirection === 'asc' }"
        (click)="
          filterChanged.emit({
            sort:
              sort?.active === filter.key && sort?.direction === 'asc'
                ? null
                : { active: filter.key, direction: 'asc' }
          })
        "
      >
        {{ filter.columnType === tableColumnTypes.number ? 'Low to high' : 'Ascending' }}
      </div>
      <div
        class="sort-btn"
        [ngClass]="{ 'sort-btn-active': sortDirection === 'desc' }"
        (click)="
          filterChanged.emit({
            sort:
              sort?.active === filter.key && sort?.direction === 'desc'
                ? null
                : { active: filter.key, direction: 'desc' }
          })
        "
      >
        {{ filter.columnType === tableColumnTypes.number ? 'High to low' : 'Descending' }}
      </div>
    </div>

    <ng-container *ngIf="!onlySort">
      <form [formGroup]="form" (ngSubmit)="submit()">
        <!-- Aggregator -->
        @if (
          isAdvancedMode &&
          (filter.columnType === tableColumnTypes.text || filter.columnType === tableColumnTypes.number)
        ) {
          <lla-dropdown-menu
            class="block mt-sm w-full"
            [fullWidth]="true"
            [items]="aggregatorItems"
            [selectedItem]="form.get('aggregator')?.value"
            (selectedItemChanged)="form.get('aggregator')?.setValue($event)"
          >
          </lla-dropdown-menu>
        }

        <!-- Search box -->
        @if (filter.searchable && filter.columnType === tableColumnTypes.checkbox) {
          <lla-input
            class="block mb-xs sticky top-0 bg-white -mt-base"
            controlName="searchText"
            size="small"
            placeholder="Search"
            icon="search"
            [normalInputId]="filter.key + 'input'"
          ></lla-input>
        }

        <div
          formArrayName="items"
          *ngFor="let itemForm of $any(itemsControl['controls']); let i = index"
          [ngClass]="{ '-mt-sm': i > 0 }"
        >
          <ng-container [formGroup]="itemForm">
            @switch (filter.columnType) {
              @case (tableColumnTypes.checkbox) {
                @if (filter.shopSelector) {
                  <lla-shops-selector
                    controlName="value"
                    [searchInputId]="filter.key + 'input'"
                  ></lla-shops-selector>
                } @else {
                  <div
                    class="flex flex-col gap-sm"
                    [ngClass]="{ 'mt-base': !filter.searchable && !filter.hideSorting }"
                  >
                    <div *ngFor="let item of visibleListItems" class="flex items-center cursor-pointer">
                      <lla-checkbox
                        [item]="item"
                        extraLabelClass="!text-sm !text-txt"
                        controlName="value"
                      ></lla-checkbox>
                    </div>
                  </div>
                }
              }
              @case (tableColumnTypes.radio) {
                <div class="mt-base flex flex-col gap-sm">
                  <div
                    *ngFor="let item of filter.listItems ?? []"
                    class="flex items-center cursor-pointer"
                  >
                    <lla-radio
                      [item]="item"
                      labelClass="!text-sm !text-txt"
                      [circleSize]="16"
                      controlName="value"
                    ></lla-radio>
                  </div>
                </div>
              }
              @case (tableColumnTypes.date) {
                <lla-range-date-picker
                  class="block mt-base"
                  [standalone]="true"
                  [value]="selectedDateInfo"
                  maxDate="Today"
                  (valueChanged)="
                    onDateChange(false, itemForm.get('value'), itemForm.get('extraValue'), $event)
                  "
                  (initailValueChanged)="
                    onDateChange(true, itemForm.get('value'), itemForm.get('extraValue'), $event)
                  "
                ></lla-range-date-picker>
              }
              @default {
                <div class="flex">
                  <lla-dropdown-menu
                    *ngIf="filter.columnType === tableColumnTypes.number || isAdvancedMode"
                    class="mr-sm mt-sm"
                    [items]="operators"
                    [selectedItem]="itemForm.get('operator')?.value"
                    (selectedItemChanged)="itemForm.get('operator')?.setValue($event)"
                  >
                  </lla-dropdown-menu>
                  <lla-input
                    class="flex-1"
                    controlName="value"
                    [showErrorMessage]="false"
                    [type]="$any(filter.columnType)"
                    size="small"
                    placeholder="Value"
                    [normalInputId]="filter.key + 'input'"
                  ></lla-input>
                  <div
                    *ngIf="isAdvancedMode"
                    class="flex ml-sm pt-xl text-txt-disabled hover:text-txt cursor-pointer"
                    (click)="removeRule(i)"
                  >
                    <svg-icon key="close" class="!text-sm"></svg-icon>
                  </div>
                </div>
              }
            }
          </ng-container>
        </div>

        <!-- Add new rule -->
        @if (
          isAdvancedMode &&
          (filter.columnType === tableColumnTypes.text || filter.columnType === tableColumnTypes.number)
        ) {
          <lla-button
            class="block w-max"
            type="button"
            size="medium-shrink"
            variant="ghost"
            icon="plus"
            (click)="addNewRule()"
          >
            Add rule
          </lla-button>
        }
      </form>

      <div
        class="sticky bg-white left-0 bottom-0 pb-base pt-base flex items-center justify-between"
        [ngClass]="{
          '!pt-xs':
            filter.columnType === tableColumnTypes.number || filter.columnType === tableColumnTypes.text,
          '!justify-end': filter.columnType === tableColumnTypes.date
        }"
      >
        <lla-button
          *ngIf="filter.columnType !== tableColumnTypes.date"
          extraBtnClass="!p-0 !text-txt-secondary"
          variant="ghost"
          size="medium"
          (click)="
            filterMenuTrigger.closeMenu();
            filterChanged.emit({
              shouldDelete: true
            })
          "
        >
          Clear
        </lla-button>

        <lla-button extraBtnClass="!p-0 !text-primary" variant="ghost" size="medium" (click)="submit()"
          >Filter</lla-button
        >
      </div>
    </ng-container>
  </div>
</mat-menu>

<ng-template #infoTooltipTemplate>
  <div class="max-w-[300px] relative">
    <div class="text-sm leading-sm text-txt font-semibold pb-xs bg-white sticky top-0">
      {{ filter.infoTitle }}
    </div>
    <div *ngIf="filter.infoSubtitle" class="text-sm leading-sm text-txt">
      {{ filter.infoSubtitle }}
    </div>
    <div
      *ngIf="filter.infoList && filter.infoList.length > 0"
      class="flex flex-wrap items-center gap-xs"
    >
      <div
        *ngFor="let item of filter.infoList; let isLast = last"
        class="capitalize text-sm leading-sm text-txt"
      >
        {{ item }}{{ !isLast ? ',' : '' }}
      </div>
    </div>
  </div>
</ng-template>

import { environment } from '@lla-platform/core/core-util'

export const ADMIN_API_URL = {
  targets: `${environment.apiUrl}/admin/location/targets`,
  usersList: `${environment.apiUrl}/admin/filters/users`,
  userPermissions: (userId?: string) =>
    `${environment.apiUrl}/admin/permissions${userId ? '/' + userId : ''}`,
  bulkEditLocations: `${environment.apiUrl}/admin/users/bulk-edit/locations`,
  bulkEditPermissions: `${environment.apiUrl}/admin/users/bulk-edit/permissions`
}

import { DayOfWeek } from '@lla-platform/core/core-data-access'
import { EmployeeRole, IEmployeeDetail } from 'employee-data-access'

export interface ISchedulerUser extends Partial<IEmployeeDetail> {
  isAvailable: boolean
  selectedLocationId?: string
  selectedLocationName?: string
}

export interface ISchedulerDayTemplate {
  label: string
  abbreviation: string
  roles: EmployeeRole[]
  count: number
  userIds?: string[]
}

export interface ISchedulerTemplate {
  workDayName: DayOfWeek
  workDayDate: Date | string
  workDayTemplate: ISchedulerDayTemplate[]
}

import { Injectable } from '@angular/core'
import { Action, Selector, State, StateContext, Store } from '@ngxs/store'
import { tap } from 'rxjs/operators'
import { sortBy, uniqBy } from 'lodash'
import { ShopsState } from '@lla-platform/shops/shops-data-access'
import { AttendanceStateModel } from './attendance.model'
import { AttendanceService } from '../attendance.service'
import { EmployeeAttendanceDetails, GetAttendance, ResyncAttendance } from './attendance.actions'
import { IAttendanceInfo } from '../../interfaces/attendance.interface'

@State<AttendanceStateModel>({
  name: 'attendance'
})
@Injectable()
export class AttendanceState {
  constructor(
    private attendanceService: AttendanceService,
    private store: Store
  ) {}

  @Selector()
  static availableWeeks(state: AttendanceStateModel) {
    return state.availableWeeks ?? []
  }

  @Selector()
  static response(state: AttendanceStateModel): IAttendanceInfo[] {
    return (state.response?.locations ?? [])?.map((el) => ({
      ...el,
      peopleOnSite: el.employees?.filter((el) => el.onSite)?.length ?? 0,
      alerts: el.employees?.map((el) => el.days?.filter((d) => d.isValid === false))?.flat()?.length ?? 0
    }))
  }

  @Selector()
  static responsePeriod(state: AttendanceStateModel) {
    return state.response?.currentWeek
  }

  @Selector()
  static employeeDetails(state: AttendanceStateModel) {
    return state.employeeDetails
  }

  @Selector()
  static allRegions(state: AttendanceStateModel) {
    const data = state.response?.locations ?? []
    return [
      { label: 'Organization', value: 'all' },
      ...sortBy(
        uniqBy(
          data
            .map((el) => ({
              label: el.locationInfo?.region ?? '',
              value: el.locationInfo?.region ?? ''
            }))
            .filter((el) => el.value !== ''),
          (x) => x.value
        ),
        (x) => x.label
      )
    ]
  }

  @Selector()
  static allDivisions(state: AttendanceStateModel) {
    const data = state.response?.locations ?? []
    return [
      { label: 'All', value: 'all' },
      ...sortBy(
        uniqBy(
          data
            .map((el) => ({
              label: el.locationInfo?.division ?? '',
              value: el.locationInfo?.division ?? ''
            }))
            .filter((el) => el.value !== ''),
          (x) => x.value
        ),
        (x) => x.label
      )
    ]
  }

  @Action(GetAttendance)
  getAttendance(ctx: StateContext<AttendanceStateModel>, { weekNumber }: GetAttendance) {
    ctx.patchState({
      response: undefined
    })
    return this.attendanceService.getAttendance(weekNumber).pipe(
      tap((res) => {
        const allShops = this.store.selectSnapshot(ShopsState.allShops)
        ctx.patchState({
          response: {
            currentWeek: res?.currentWeek,
            locations: (res?.locations ?? []).map((el) => ({
              ...el,
              locationInfo: allShops.find((s) => s.id === el.location?.id)
            }))
          }
        })
      })
    )
  }

  @Action(ResyncAttendance)
  resyncAttendance(ctx: StateContext<AttendanceStateModel>, { weekNumber }: ResyncAttendance) {
    ctx.patchState({
      response: undefined
    })
    return this.attendanceService.resyncAttendance(weekNumber).pipe(
      tap((res) => {
        const allShops = this.store.selectSnapshot(ShopsState.allShops)
        ctx.patchState({
          response: {
            currentWeek: res?.currentWeek,
            locations: (res?.locations ?? []).map((el) => ({
              ...el,
              locationInfo: allShops.find((s) => s.id === el.location?.id)
            }))
          }
        })
      })
    )
  }

  @Action(EmployeeAttendanceDetails)
  employeeAttendanceDetails(
    ctx: StateContext<AttendanceStateModel>,
    { employeeId, from }: EmployeeAttendanceDetails
  ) {
    ctx.patchState({
      employeeDetails: undefined
    })
    return this.attendanceService.employeeAttendanceDetails(employeeId, from).pipe(
      tap((res) => {
        ctx.patchState({
          employeeDetails: res
        })
      })
    )
  }
}

import { Injectable } from '@angular/core'
import { Action, Selector, State, StateContext } from '@ngxs/store'
import { tap } from 'rxjs/operators'
import { ReceivableService } from '../receivable.service'
import { ArPaymentsStateModel } from './ar-payments.model'
import { GetArPaymentsSummary } from './ar-payments.actions'

@State<ArPaymentsStateModel>({
  name: 'arPayments'
})
@Injectable()
export class ArPaymentsState {
  constructor(private receivableService: ReceivableService) {}

  @Selector()
  static summaryResponse(state: ArPaymentsStateModel) {
    return state.summaryResponse
  }

  @Action(GetArPaymentsSummary)
  getArPaymentsSummary(ctx: StateContext<ArPaymentsStateModel>, { payload }: GetArPaymentsSummary) {
    ctx.patchState({
      summaryResponse: undefined
    })
    return this.receivableService.getArPaymentsSummary(payload).pipe(
      tap((res) => {
        ctx.patchState({
          summaryResponse: res
        })
      })
    )
  }
}

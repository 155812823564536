import { Component, EventEmitter, inject, Input, OnChanges, Output, SimpleChanges } from '@angular/core'
import { environment, TABLE_CONSTANTS } from '@lla-platform/core/core-util'
import {
  ArInvoicesState,
  GetInvoice,
  IInvoiceDetailResponse
} from '@lla-platform/receivable/receivable-data-access'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { Store } from '@ngxs/store'
import { ToastrService } from 'ngx-toastr'
import { Observable, finalize } from 'rxjs'

@UntilDestroy()
@Component({
  selector: 'lla-invoice-info',
  templateUrl: './invoice-info.component.html',
  styleUrls: ['./invoice-info.component.scss'],
  standalone: false
})
export class InvoiceInfoComponent implements OnChanges {
  @Input() invoiceId?: string
  @Input() showCloseButton = true
  @Input() forModal = false
  @Output() invoiceInfoClosed = new EventEmitter()

  invoiceResponse$: Observable<IInvoiceDetailResponse> = inject(Store).select(
    ArInvoicesState.invoiceResponse
  )

  isLoading = false
  websiteUrl = environment.websiteUrl
  emptyGuid = TABLE_CONSTANTS.EMPTY_GUID

  constructor(
    private store: Store,
    public toastrService: ToastrService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes['invoiceId']?.currentValue) {
      return
    }

    if (changes['invoiceId']?.previousValue !== changes['invoiceId']?.currentValue) {
      this.isLoading = true
      setTimeout(() => {
        this.getInvoice()
      })
    }
  }

  getInvoice() {
    if (!this.invoiceId) {
      return
    }

    this.store
      .dispatch(new GetInvoice(this.invoiceId))
      .pipe(
        untilDestroyed(this),
        finalize(() => {
          this.isLoading = false
        })
      )
      .subscribe()
  }
}

import { Injectable } from '@angular/core'
import { Action, Selector, State, StateContext } from '@ngxs/store'
import { tap } from 'rxjs/operators'
import { AdminStateModel } from './admin.model'
import { AdminService } from '../admin.service'
import {
  AdminBulkEditLocations,
  AdminBulkEditPermissions,
  AdminGetAllPermissions,
  AdminGetUsersList,
  AdminGetShopTires,
  AdminGetUserPermissions,
  AdminUpdateShopTires,
  AdminUpdateUserPermissions
} from './admin.actions'

@State<AdminStateModel>({
  name: 'admin'
})
@Injectable()
export class AdminState {
  constructor(private adminService: AdminService) {}

  @Selector()
  static shopTiers(state: AdminStateModel) {
    return state.shopTiers
  }

  @Selector()
  static usersListResponse(state: AdminStateModel) {
    return state.usersListResponse
  }

  @Selector()
  static userPermissions(state: AdminStateModel) {
    return state.userPermissions
  }

  @Selector()
  static allPermissions(state: AdminStateModel) {
    return state.allPermissions
  }

  @Action(AdminGetShopTires)
  adminGetShopTires(ctx: StateContext<AdminStateModel>) {
    ctx.patchState({
      shopTiers: undefined
    })
    return this.adminService.getShopTires().pipe(
      tap((res) => {
        ctx.patchState({
          shopTiers: res?.locations ?? []
        })
      })
    )
  }

  @Action(AdminUpdateShopTires)
  adminUpdateShopTires(ctx: StateContext<AdminStateModel>, { payload }: AdminUpdateShopTires) {
    return this.adminService.updateShopTires(payload)
  }

  @Action(AdminGetUsersList)
  adminGetUsersList(ctx: StateContext<AdminStateModel>, { info }: AdminGetUsersList) {
    ctx.patchState({
      usersListResponse: undefined
    })
    return this.adminService.getUsersList(info).pipe(
      tap((res) => {
        ctx.patchState({
          usersListResponse: res
        })
      })
    )
  }

  @Action(AdminGetUserPermissions)
  adminGetUserPermissions(ctx: StateContext<AdminStateModel>, { userId }: AdminGetUserPermissions) {
    return this.adminService.getUserPermissions(userId).pipe(
      tap((res) => {
        ctx.patchState({
          userPermissions: res
        })
      })
    )
  }

  @Action(AdminUpdateUserPermissions)
  adminUpdateUserPermissions(
    ctx: StateContext<AdminStateModel>,
    { userId, payload }: AdminUpdateUserPermissions
  ) {
    return this.adminService.updateUserPermissions(userId, payload)
  }

  @Action(AdminGetAllPermissions)
  adminGetAllPermissions(ctx: StateContext<AdminStateModel>) {
    const allPermissions = ctx.getState()?.allPermissions
    if (allPermissions) {
      return allPermissions
    }

    ctx.patchState({
      allPermissions: undefined
    })
    return this.adminService.getAllPermissions().pipe(
      tap((res) => {
        ctx.patchState({
          allPermissions: res
        })
      })
    )
  }

  @Action(AdminBulkEditLocations)
  adminBulkEditLocations(ctx: StateContext<AdminStateModel>, { payload }: AdminBulkEditLocations) {
    return this.adminService.bulkEditLocations(payload)
  }

  @Action(AdminBulkEditPermissions)
  adminBulkEditPermissions(ctx: StateContext<AdminStateModel>, { payload }: AdminBulkEditPermissions) {
    return this.adminService.bulkEditPermissions(payload)
  }
}

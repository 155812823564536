<div
  class="relative md:mr-2xl text-primary flex items-center justify-center w-[40px] md:w-auto h-[40px] md:h-auto rounded-sm md:rounded-0 border border-primary md:border-0 p-sm md:p-0 cursor-pointer"
  (click)="filterButtonClicked.emit()"
>
  <svg-icon key="filters" class="!text-base md:!text-sm"></svg-icon>
  <span class="hidden md:block pl-sm text-sm leading-sm">Filters</span>
  <div
    class="absolute right-2xs bottom-2xs md:right-auto md:bottom-auto md:relative md:ml-sm text-white bg-primary text-2xs leading-2xs font-semibold rounded-full w-[18px] h-[18px] flex items-center justify-center"
    *ngIf="numberOfFilters && numberOfFilters > 0"
  >
    {{ numberOfFilters }}
  </div>
</div>

import { Injectable } from '@angular/core'
import {
  HttpService,
  IExportFileResponse,
  TableActionsService
} from '@lla-platform/core/core-data-access'
import { Observable } from 'rxjs'
import { TECHNICIAN_API_URL } from './technician-api-routes'
import { ITechnicianListRequest, ITechnicianListResponse } from '../interfaces/technician-list.interface'
import { DateService } from '@lla-platform/core/core-util'
import {
  ITechnicianSummaryRequest,
  ILaborSummaryResponse,
  ITechnicianSummaryResponse
} from '../interfaces/technician-summary.interface'

@Injectable({
  providedIn: 'root'
})
export class TechnicianService {
  constructor(
    private http: HttpService,
    private tableActionsService: TableActionsService,
    private dateService: DateService
  ) {}

  getLaborSummary(payload: ITechnicianSummaryRequest): Observable<ILaborSummaryResponse> {
    const { from, to } = payload
    return this.http.post(TECHNICIAN_API_URL.laborSummary, {
      ...payload,
      from: this.dateService.momentToDateString(from),
      to: this.dateService.momentToDateString(to)
    })
  }

  exportLaborSummary(payload: ITechnicianSummaryRequest): Observable<IExportFileResponse> {
    const { from, to } = payload
    return this.http.post(TECHNICIAN_API_URL.exportLaborSummary, {
      ...payload,
      from: this.dateService.momentToDateString(from),
      to: this.dateService.momentToDateString(to)
    })
  }

  getTechnicianSummary(payload: ITechnicianSummaryRequest): Observable<ITechnicianSummaryResponse> {
    const { from, to } = payload
    return this.http.post(TECHNICIAN_API_URL.technicianSummary, {
      ...payload,
      from: this.dateService.momentToDateString(from),
      to: this.dateService.momentToDateString(to)
    })
  }

  exportTechnicianSummary(payload: ITechnicianSummaryRequest): Observable<IExportFileResponse> {
    const { from, to } = payload
    return this.http.post(TECHNICIAN_API_URL.exportTechnicianSummary, {
      ...payload,
      from: this.dateService.momentToDateString(from),
      to: this.dateService.momentToDateString(to)
    })
  }

  getTechnicianList(info: ITechnicianListRequest): Observable<ITechnicianListResponse> {
    return this.http.post(TECHNICIAN_API_URL.technicianList, {
      ...this.tableActionsService.createRequestPayload(info),
      startDate: this.dateService.momentToDateString(info.from),
      endDate: this.dateService.momentToDateString(info.to)
    })
  }

  exportTechnicianList(info: ITechnicianListRequest): Observable<IExportFileResponse> {
    return this.http.post(TECHNICIAN_API_URL.exportTechnicianList, {
      ...this.tableActionsService.createRequestPayload(info),
      startDate: this.dateService.momentToDateString(info.from),
      endDate: this.dateService.momentToDateString(info.to)
    })
  }
}

import { Injectable } from '@angular/core'
import { GetAllShops } from '@lla-platform/shops/shops-data-access'
import { GetAvailableWeeksForWeeklyTarget } from '@lla-platform/targets/targets-data-access'
import { Store } from '@ngxs/store'
import { catchError, map, Observable, of, take } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class InfoGuard {
  constructor(private store: Store) {}
  canActivate(): Observable<boolean> {
    return this.store.dispatch([new GetAllShops(), new GetAvailableWeeksForWeeklyTarget()]).pipe(
      catchError(() => {
        return of(null)
      }),
      take(1),
      map(() => true)
    )
  }
}

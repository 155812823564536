import { Component, Input, OnInit } from '@angular/core'
import { ControlContainer, UntypedFormControl } from '@angular/forms'
import { UntilDestroy } from '@ngneat/until-destroy'
import { ISelectItem } from '../input/input.interface'

@UntilDestroy()
@Component({
  selector: 'lla-radio',
  templateUrl: './radio.component.html',
  styleUrls: ['./radio.component.scss'],
  standalone: false
})
export class RadioComponent implements OnInit {
  @Input() controlName: string
  @Input() item: ISelectItem
  @Input() labelClass = ''
  @Input() circleSize = 18

  formControl: UntypedFormControl

  constructor(private controlContainer: ControlContainer) {}

  ngOnInit(): void {
    this.formControl = this.controlContainer.control?.get(this.controlName) as UntypedFormControl
  }
}

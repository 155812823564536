export const environment = {
  production: false,
  name: 'dev',
  apiUrl: 'https://dev.jarvis-lla.com/api/v1',
  websiteUrl: 'https://dev.jarvis-lla.com',
  googlePlacesKey: 'AIzaSyBVuu27h1inWvon6YgA3byPYU1HCJRwCKo',
  googleAnalyticsToken: 'G-BDR5V65N22',
  pageSize: 20,
  dashboardWidgetsList: {
    top: 10,
    bottom: 3
  },
  jwtSecret: 'simple_jwt_secret'
}

import { ISelectItem } from '@lla-platform/core/core-ui'

export enum Permission {
  Reviews = 'Reviews',
  Receivables = 'Receivables',
  Invoices = 'Invoices',
  Contacts = 'Contacts',
  Calls = 'Calls',
  Reports = 'Reports',
  Inventories = 'Inventories',
  Admin = 'Admin',
  PackageGp = 'PackageGp',
  Tracker = 'Tracker',
  LostSales = 'LostSales',
  LostTiresReport = 'LostTiresReport',
  Tires = 'Tires',
  WhoToCall = 'WhoToCall',
  WorkOrders = 'WorkOrders',
  ObjectiveComps = 'ObjectiveComps',
  ManagePromotions = 'ManagePromotions',
  Attendance = 'Attendance',
  EmployeeManagement = 'EmployeeManagement',
  LaborSummary = 'LaborSummary',
  TechnicianEfficiency = 'TechnicianEfficiency'
}

export const PermissionToLabel: { [key: string]: string } = {
  Reviews: 'Reviews',
  Receivables: 'Receivables',
  Invoices: 'Invoices',
  Contacts: 'Contacts',
  Calls: 'Calls',
  Reports: 'Reports',
  Inventories: 'Inventories',
  Admin: 'Admin',
  PackageGp: 'Package Gp',
  Tracker: 'Tracker',
  LostSales: 'Lost Sales',
  LostTiresReport: 'Lost Tires Report',
  Tires: 'Tires',
  WhoToCall: 'Who to Call',
  WorkOrders: 'Work Orders',
  ObjectiveComps: 'Objective Comps',
  ManagePromotions: 'Manage Promotions',
  Attendance: 'Attendance',
  EmployeeManagement: 'Employee Management',
  LaborSummary: 'Labor Summary',
  TechnicianEfficiency: 'Technician Efficiency'
}

export const PermissionItems: ISelectItem[] = [
  {
    label: PermissionToLabel[Permission.Reviews],
    value: Permission.Reviews
  },
  {
    label: PermissionToLabel[Permission.Receivables],
    value: Permission.Receivables
  },
  {
    label: PermissionToLabel[Permission.Invoices],
    value: Permission.Invoices
  },
  {
    label: PermissionToLabel[Permission.Contacts],
    value: Permission.Contacts
  },
  {
    label: PermissionToLabel[Permission.Calls],
    value: Permission.Calls
  },
  {
    label: PermissionToLabel[Permission.Reports],
    value: Permission.Reports
  },
  {
    label: PermissionToLabel[Permission.Inventories],
    value: Permission.Inventories
  },
  {
    label: PermissionToLabel[Permission.Admin],
    value: Permission.Admin
  },
  {
    label: PermissionToLabel[Permission.PackageGp],
    value: Permission.PackageGp
  },
  {
    label: PermissionToLabel[Permission.Tracker],
    value: Permission.Tracker
  },
  {
    label: PermissionToLabel[Permission.LostSales],
    value: Permission.LostSales
  },
  {
    label: PermissionToLabel[Permission.LostTiresReport],
    value: Permission.LostTiresReport
  },
  {
    label: PermissionToLabel[Permission.Tires],
    value: Permission.Tires
  },
  {
    label: PermissionToLabel[Permission.WhoToCall],
    value: Permission.WhoToCall
  },
  {
    label: PermissionToLabel[Permission.WorkOrders],
    value: Permission.WorkOrders
  },
  {
    label: PermissionToLabel[Permission.ObjectiveComps],
    value: Permission.ObjectiveComps
  },
  {
    label: PermissionToLabel[Permission.ManagePromotions],
    value: Permission.ManagePromotions
  },
  {
    label: PermissionToLabel[Permission.Attendance],
    value: Permission.Attendance
  },
  {
    label: PermissionToLabel[Permission.EmployeeManagement],
    value: Permission.EmployeeManagement
  },
  {
    label: PermissionToLabel[Permission.LaborSummary],
    value: Permission.LaborSummary
  },
  {
    label: PermissionToLabel[Permission.TechnicianEfficiency],
    value: Permission.TechnicianEfficiency
  }
]

import { Injectable } from '@angular/core'
import { Action, Selector, State, StateContext } from '@ngxs/store'
import { SchedulerStateModel } from './scheduler.model'
import { SchedulerService } from '../scheduler.service'
import { CopySchedulerData, SetSchedulerData } from './scheduler.actions'
import { SchedulerInitialData } from '../../constants/scheduler.constant'
import { cloneDeep } from 'lodash'

@State<SchedulerStateModel>({
  name: 'scheduler'
})
@Injectable()
export class SchedulerState {
  constructor(private schedulerService: SchedulerService) {}

  @Selector()
  static data(state: SchedulerStateModel) {
    return state.data ?? SchedulerInitialData
  }

  @Action(SetSchedulerData)
  setSchedulerData(
    ctx: StateContext<SchedulerStateModel>,
    { workDayDate, templateIndex, userIds }: SetSchedulerData
  ) {
    const data = cloneDeep(ctx.getState()?.data ?? SchedulerInitialData)
    const workDayIndex = data.findIndex((el) => el.workDayDate === workDayDate)
    if (workDayIndex < 0) {
      return
    }
    if (data[workDayIndex] && data[workDayIndex]?.workDayTemplate?.[templateIndex]) {
      data[workDayIndex].workDayTemplate[templateIndex].userIds = userIds
    }
    ctx.patchState({
      data
    })
  }

  @Action(CopySchedulerData)
  copySchedulerData(ctx: StateContext<SchedulerStateModel>, { dayIndex }: CopySchedulerData) {
    const data = cloneDeep(ctx.getState()?.data ?? SchedulerInitialData)
    const dayInfo = data[dayIndex]
    const previousDayTemplates = data[dayIndex - 1].workDayTemplate
    data[dayIndex] = {
      ...dayInfo,
      workDayTemplate: dayInfo.workDayTemplate.map((el) => ({
        ...el,
        userIds:
          previousDayTemplates.find((p) => p.abbreviation === el.abbreviation)?.userIds ?? el.userIds
      }))
    }
    ctx.patchState({
      data
    })
  }
}

import { Component, Input } from '@angular/core'
import { UntilDestroy } from '@ngneat/until-destroy'
import { InvoiceInfoDialogComponent } from '../invoice-info-dialog/invoice-info-dialog.component'
import { MatDialog } from '@angular/material/dialog'
import { ToastrService } from 'ngx-toastr'
import { TotalRowUniqueId } from '../../widgets/table-filter/table-filter.constant'

@UntilDestroy()
@Component({
  selector: 'lla-invoice-info-cell',
  templateUrl: './invoice-info-cell.component.html',
  styleUrls: ['./invoice-info-cell.component.scss'],
  standalone: false
})
export class InvoiceInfoCellComponent {
  @Input() rowId: string
  @Input() invoiceNumber: string
  @Input() invoiceId: number
  @Input() locationName: string

  totalRowUniqueId = TotalRowUniqueId

  constructor(
    private dialogService: MatDialog,
    public toastrService: ToastrService
  ) {}

  public openInvoiceInfoModal() {
    if (!this.invoiceId) {
      return
    }

    this.dialogService.open(InvoiceInfoDialogComponent, {
      autoFocus: false,
      panelClass: 'normal-modal',
      data: {
        invoiceId: this.invoiceId,
        shopName: this.locationName
      }
    })
  }
}

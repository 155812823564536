import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core'
import { ISelectItem } from '@lla-platform/core/core-ui'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { PillSize } from '../pill/pill.component'
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms'

export interface IDisableItem {
  value: any
  reason?: string
}

@UntilDestroy()
@Component({
  selector: 'lla-items-selector-filter',
  templateUrl: './items-selector-filter.component.html',
  styleUrls: ['./items-selector-filter.component.scss'],
  standalone: false
})
export class ItemsSelectorFilterComponent implements OnInit, OnChanges {
  @Input() panelWidth: 'auto' | null = 'auto'
  @Input() selectMenuInputId: string
  @Input() searchable = true
  @Input() inlineSearch = false
  @Input() icon = 'garage'
  @Input() label = 'Select location'
  @Input() allItems: ISelectItem[] = []
  @Input() disableItems: IDisableItem[] = []
  @Input() selectedId: any
  @Input() pillSize: PillSize = 'm'
  @Input() extraClasses = ''
  @Input() selectorClass = ''
  @Output() selectedIdChanged = new EventEmitter<any>()

  selectedItems: ISelectItem[] = []

  form: UntypedFormGroup

  constructor(private fb: UntypedFormBuilder) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      value: [this.selectedId],
      searchText: [null]
    })

    this.form
      .get('searchText')
      ?.valueChanges.pipe(untilDestroyed(this))
      .subscribe((value: string) => {
        this.filterItems(value)
      })

    this.form
      .get('value')
      ?.valueChanges.pipe(untilDestroyed(this))
      .subscribe((value: string) => {
        this.selectedIdChanged.emit(value)
        this.focusOnInputId()
      })
  }

  ngOnChanges(): void {
    this.filterItems()
  }

  filterItems(value?: string) {
    const trimValue = value?.toLowerCase().trim() ?? ''
    if (trimValue === '') {
      this.selectedItems = [...this.allItems]
    } else {
      this.selectedItems = this.allItems.filter((el) => el.label.toLowerCase().includes(trimValue))
    }
  }

  getDisableItem(value: any) {
    return this.disableItems?.find((el) => el.value === value)
  }

  selectedItemName() {
    return this.allItems.find((el) => el.value === this.selectedId)?.label
  }

  focusOnInputId() {
    const inputElement = document.getElementById(this.selectMenuInputId)
    if (inputElement) {
      inputElement?.focus()
    }
  }

  searchOnPress(event: KeyboardEvent) {
    if (this.inlineSearch && event?.key) {
      const foundItems = this.allItems
        .filter((el) => el.label.toLowerCase().startsWith(event.key))
        .map((el) => el.value)

      if (foundItems.length > 0) {
        let valueIndex = 0
        const currentIndex = foundItems.findIndex((el) => el === this.form.get('value')?.value)

        if (currentIndex !== foundItems.length - 1 && currentIndex + 1 < foundItems.length) {
          valueIndex = currentIndex + 1
        }
        this.form.get('value')?.setValue(foundItems[valueIndex])
      }
    }
  }
}

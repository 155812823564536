import { Permission } from '@lla-platform/users/users-data-access'

import {
  IAdvancedTable,
  IBaseShop,
  IPaginationFilteredResponse
} from '@lla-platform/core/core-data-access'

export interface IAdminUsersListItem extends IAdvancedTable {
  id: string
  location: IBaseShop
  permissions: Permission[]
  fullName: string
  phoneNumber: string
  email: string
  created: string
  numberOfLocations: number
}

export interface IAdminUsersListResponse extends IPaginationFilteredResponse {
  users: IAdminUsersListItem[]
}

import { Injectable } from '@angular/core'
import { Action, Selector, State, StateContext } from '@ngxs/store'
import { tap } from 'rxjs/operators'
import { TechnicianStateModel } from './technician.model'
import { TechnicianService } from '../technician.service'
import { GetTechnicianList, GetLaborSummary, GetTechnicianSummary } from './technician.actions'
import {
  ILaborSummaryResponse,
  ITechnicianSummaryResponse
} from '../../interfaces/technician-summary.interface'

@State<TechnicianStateModel>({
  name: 'technician'
})
@Injectable()
export class TechnicianState {
  constructor(private technicianService: TechnicianService) {}

  @Selector()
  static laborSummaryResponse(state: TechnicianStateModel): ILaborSummaryResponse | undefined {
    return state.laborSummaryResponse
  }

  @Selector()
  static summaryResponse(state: TechnicianStateModel): ITechnicianSummaryResponse | undefined {
    return state.summaryResponse
  }

  @Selector()
  static listResponse(state: TechnicianStateModel) {
    return state.listResponse
  }

  @Action(GetLaborSummary)
  getLaborSummary(ctx: StateContext<TechnicianStateModel>, { payload }: GetLaborSummary) {
    ctx.patchState({
      laborSummaryResponse: undefined
    })
    return this.technicianService.getLaborSummary(payload).pipe(
      tap((res) => {
        ctx.patchState({
          laborSummaryResponse: res
        })
      })
    )
  }

  @Action(GetTechnicianSummary)
  getTechnicianSummary(ctx: StateContext<TechnicianStateModel>, { payload }: GetTechnicianSummary) {
    ctx.patchState({
      summaryResponse: undefined
    })
    return this.technicianService.getTechnicianSummary(payload).pipe(
      tap((res) => {
        ctx.patchState({
          summaryResponse: res
        })
      })
    )
  }

  @Action(GetTechnicianList)
  getTechnicianList(ctx: StateContext<TechnicianStateModel>, { info }: GetTechnicianList) {
    ctx.patchState({
      listResponse: undefined
    })
    return this.technicianService.getTechnicianList(info).pipe(
      tap((res) => {
        ctx.patchState({
          listResponse: res
        })
      })
    )
  }
}

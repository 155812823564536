import { ISelectItem } from '@lla-platform/core/core-ui'
import { sortBy } from 'lodash'

export enum EmployeeRole {
  NotSet = 'NotSet',
  Manager = 'Manager',
  ServiceAdvisor = 'ServiceAdvisor',
  Technician = 'Technician',
  GeneralService = 'GeneralService',
  Driver = 'Driver',
  Office = 'Office',
  Foreman = 'Foreman'
}

export const EmployeeRoleToLabel: { [key: string]: string } = {
  NotSet: 'Not Set',
  Manager: 'Manager',
  ServiceAdvisor: 'Service Advisor',
  Technician: 'Technician',
  GeneralService: 'General Service',
  Driver: 'Driver',
  Office: 'Office',
  Foreman: 'Foreman'
}

export const EmployeeRoleItems: ISelectItem[] = [
  {
    label: EmployeeRoleToLabel[EmployeeRole.NotSet],
    value: EmployeeRole.NotSet
  },
  ...sortBy(
    [
      {
        label: EmployeeRoleToLabel[EmployeeRole.Manager],
        value: EmployeeRole.Manager
      },
      {
        label: EmployeeRoleToLabel[EmployeeRole.ServiceAdvisor],
        value: EmployeeRole.ServiceAdvisor
      },
      {
        label: EmployeeRoleToLabel[EmployeeRole.Technician],
        value: EmployeeRole.Technician
      },
      {
        label: EmployeeRoleToLabel[EmployeeRole.GeneralService],
        value: EmployeeRole.GeneralService
      },
      {
        label: EmployeeRoleToLabel[EmployeeRole.Driver],
        value: EmployeeRole.Driver
      },
      {
        label: EmployeeRoleToLabel[EmployeeRole.Office],
        value: EmployeeRole.Office
      },
      {
        label: EmployeeRoleToLabel[EmployeeRole.Foreman],
        value: EmployeeRole.Foreman
      }
    ],
    (x) => x.label
  )
]

import { ITimePeriod } from '@lla-platform/core/core-data-access'

export interface IArReceivablesWidgetTotal {
  invoices: number
  totalSales: number
  totalSalesFormatted: string
}

export interface IArReceivablesWidgetRegion {
  group: string
  total?: IArReceivablesWidgetTotal
}

export interface IArReceivablesWidgetResponse {
  period: ITimePeriod
  total: IArReceivablesWidgetTotal
  groups: IArReceivablesWidgetRegion[]
}

import { Component, EventEmitter, Input, Output } from '@angular/core'
import { Sort } from '@angular/material/sort'
import { UntilDestroy } from '@ngneat/until-destroy'
import { SortStateService } from '../../services/sort-state.services'
import { NgxTippyProps } from 'ngx-tippy-wrapper'

export interface ITableHeaderSortInfo {
  columnName: string
  title?: string
}

@UntilDestroy()
@Component({
  selector: 'lla-table-header',
  templateUrl: './table-header.component.html',
  styleUrls: ['./table-header.component.scss'],
  standalone: false
})
export class TableHeaderComponent {
  @Input() label: string
  @Input() sortInfo: ITableHeaderSortInfo[] = []
  @Input() extraClass = ''
  @Input() rightAlign = false
  @Input() centerAlign = false
  @Input() sortable = true
  @Input() simpleSort = false
  @Input() sortState?: Sort
  @Input() infoList?: string[]
  @Input() infoTitle?: string
  @Input() infoSubtitle?: string
  @Output() sortChanged = new EventEmitter()

  tooltipOption: NgxTippyProps = {
    arrow: false,
    hideOnClick: true,
    placement: 'bottom-start'
  }

  constructor(public sortStateService: SortStateService) {}

  isHeaderActive() {
    return (
      this.sortState &&
      this.sortState.direction &&
      (this.sortState.direction as string) !== '' &&
      this.sortInfo.find((el) => el.columnName === this.sortState?.active)
    )
  }

  emitSimpleSort() {
    if (this.sortInfo && this.sortInfo.length !== 1) {
      return
    }
    this.sortChanged.emit(
      this.sortStateService.update(
        { active: this.sortInfo[0].columnName, direction: 'asc' },
        this.sortState,
        true
      )
    )
  }
}

<div class="lla-page-container" *ngIf="currentUser$ | async as currentUser">
  <lla-page-title [title]="'My Profile'">
    <div slot="subTitle">Hello {{ currentUser.firstName }} {{ currentUser.lastName }}</div>
  </lla-page-title>

  <div class="mt-2xl">
    <lla-tabs
      [sections]="sections"
      (selectedSectionChanged)="selectedRoute = $event; router.navigateByUrl(selectedRoute)"
      [selectedSection]="selectedRoute"
      [smallScreensSpaceBetween]="true"
    ></lla-tabs>
    <div class="mt-2xl">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>

<!-- Just small media: This div used to reseve the space for header even with absolute elements -->
<div class="h-[48px] lg:hidden"></div>

<!-- Main content -->
<div class="border-b border-gray-100">
  <div
    class="fixed top-0 z-[300] lg:relative w-full bg-white py-sm mx-auto px-base lg:px-lg flex items-center justify-center lg:block border-b border-gray-100 lg:border-b-0"
  >
    <div
      class="flex lg:hidden absolute left-base cursor-pointer top-base text-txt"
      (click)="mainSidebar?.open()"
    >
      <svg-icon key="menu" class="!text-xl"></svg-icon>
    </div>

    <div class="h-[32px] hidden lg:flex items-center">
      <div
        (click)="showLargeMenu = !showLargeMenu; showLargeMenuChanged.emit(showLargeMenu)"
        class="h-full w-[100px] group cursor-pointer flex items-center"
        *ngIf="showLargeMenu"
      >
        <img class="group-hover:hidden w-full" src="assets/images/icons/new-big-logo.svg" alt="Logo" />
        <div class="hidden group-hover:flex w-full h-full items-center text-primary">
          <svg-icon key="sidebar-middle" class="!text-lg"></svg-icon>
        </div>
      </div>
      <div
        (click)="showLargeMenu = !showLargeMenu; showLargeMenuChanged.emit(showLargeMenu)"
        *ngIf="!showLargeMenu"
        class="h-full w-[100px] flex items-center text-primary cursor-pointer"
      >
        <svg-icon key="sidebar-left" class="!text-lg"></svg-icon>
      </div>
    </div>

    <img
      class="lg:hidden h-[32px] cursor-pointer"
      src="assets/images/icons/new-big-logo.svg"
      alt="Logo"
      routerLink="/dashboard"
    />

    <div class="absolute top-base right-base lg:right-2xl">
      <button
        (click)="menuTrigger.openMenu(); mainSidebar?.close()"
        class="relative flex items-center justify-end"
      >
        <div
          class="pr-base hidden lg:block text-base leading-base text-txt"
          *ngIf="currentUser$ | async; let currentUser"
        >
          {{ currentUser.firstName }} {{ currentUser.lastName }}
        </div>
        <div class="flex text-txt">
          <svg-icon key="settings" class="!text-xl lg:!text-lg"></svg-icon>
        </div>
      </button>
      <div [matMenuTriggerFor]="profileMenu" #menuTrigger="matMenuTrigger"></div>
      <mat-menu
        #profileMenu="matMenu"
        [hasBackdrop]="true"
        backdropClass="profile-menu-backdrop"
        class="profile-mat-menu"
      >
        <ng-template matMenuContent>
          <div
            class="block lg:hidden text-base leading-base text-txt font-semibold border-b border-gray-100 px-base py-sm"
            *ngIf="currentUser$ | async; let currentUser"
          >
            {{ currentUser.firstName }} {{ currentUser.lastName }}
          </div>
          <div class="flex flex-col menu-buttons">
            <a
              *ngFor="let item of profileMenuRoutes"
              class="profile-menu-item"
              [routerLink]="item.path"
              routerLinkActive="text-primary"
              (click)="mainSidebar?.close()"
            >
              <div class="flex text-txt">
                <svg-icon class="mr-base !text-sm" [key]="item.icon"></svg-icon>
              </div>
              <span>{{ item.label }}</span>
            </a>

            <a class="profile-menu-item !pb-sm border-t border-gray-100" (click)="logout()">
              <div class="flex text-txt">
                <svg-icon class="mr-base !text-sm" key="exit"></svg-icon>
              </div>
              <span>Log Out</span>
            </a>
          </div>
        </ng-template>
      </mat-menu>
    </div>
  </div>
</div>

<!-- Just small media: Closable menu  -->
<div sidebarjs="mainSidebar">
  <lla-main-menu
    [forDesktop]="false"
    [mainMenuRoutes]="mainMenuRoutes"
    (menuItemClicked)="mainSidebar?.close()"
  ></lla-main-menu>
</div>

<div
  class="{{ extraClass }} w-full flex items-center text-txt font-semibold text-sm leading-sm"
  [ngClass]="{
    'justify-end text-right': rightAlign,
    'justify-center text-center': centerAlign,
    'cursor-pointer': sortable,
    '!text-primary': isHeaderActive()
  }"
  (click)="simpleSort ? emitSimpleSort() : menuTrigger.openMenu()"
>
  <span>{{ label }}</span>
  <div
    *ngIf="infoTitle"
    [ngxTippy]="infoTooltipTemplate"
    [tippyProps]="tooltipOption"
    class="hidden md:flex ml-[6px] text-primary"
  >
    <svg-icon key="info-circle" class="!text-xs"></svg-icon>
  </div>
  <span class="pl-[6px]" *ngIf="isHeaderActive()">
    <svg-icon
      key="arrow-up-full"
      fontSize="9px"
      [ngClass]="{ 'rotate-180': sortState?.direction === 'desc' }"
    ></svg-icon>
  </span>
</div>
<div [matMenuTriggerFor]="sortable ? sortMenu : null" #menuTrigger="matMenuTrigger"></div>

<!-- Filter Menu -->
<mat-menu
  #sortMenu="matMenu"
  class="table-header-sort-menu"
  [xPosition]="rightAlign ? 'before' : 'after'"
  [hasBackdrop]="true"
  backdropClass="lla-transparent-menu-backdrop"
>
  <ng-template matMenuContent>
    <div class="p-base" (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()">
      <div *ngFor="let info of sortInfo" class="mt-sm first:mt-0 flex items-center justify-between">
        <div *ngIf="info.title" class="mr-base font-semibold text-sm leading-sm text-txt">
          {{ info.title }}
        </div>
        <div class="flex justify-end items-center">
          <div
            class="mr-xs sort-pill"
            [ngClass]="{
              'sort-pill-active':
                sortState && sortState.active === info.columnName && sortState.direction === 'desc'
            }"
            (click)="
              sortChanged.emit(
                sortStateService.update({ active: info.columnName, direction: 'desc' }, sortState)
              );
              menuTrigger.closeMenu()
            "
          >
            <div class="sort-pill-title">High to Low</div>
          </div>

          <div
            class="sort-pill"
            [ngClass]="{
              'sort-pill-active':
                sortState && sortState.active === info.columnName && sortState.direction === 'asc'
            }"
            (click)="
              sortChanged.emit(
                sortStateService.update({ active: info.columnName, direction: 'asc' }, sortState)
              );
              menuTrigger.closeMenu()
            "
          >
            <div class="sort-pill-title">Low to High</div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</mat-menu>

<ng-template #infoTooltipTemplate>
  <div class="max-w-[300px] relative">
    <div class="text-sm leading-sm text-txt font-semibold pb-xs bg-white sticky top-0">
      {{ infoTitle }}
    </div>
    <div *ngIf="infoSubtitle" class="text-sm leading-sm text-txt">
      {{ infoSubtitle }}
    </div>
    <div *ngIf="infoList && infoList.length > 0" class="flex flex-wrap items-center gap-xs">
      <div
        *ngFor="let item of infoList; let isLast = last"
        class="capitalize text-sm leading-sm text-txt"
      >
        {{ item }}{{ !isLast ? ',' : '' }}
      </div>
    </div>
  </div>
</ng-template>

import { Injectable } from '@angular/core'
import { Action, Selector, State, StateContext } from '@ngxs/store'
import { tap } from 'rxjs/operators'
import { ReportsStateModel } from './reports.model'
import { ReportsService } from '../reports.service'
import { GetCallsReport, GetSalesReport } from './reports.actions'
import { ISalesReportDetails } from '../../interfaces/sales-report.interface'

@State<ReportsStateModel>({
  name: 'reports'
})
@Injectable()
export class ReportsState {
  constructor(private reportsService: ReportsService) {}

  @Selector()
  static callsReports(state: ReportsStateModel) {
    return state.callsReports
  }

  @Selector()
  static salesReportsDetails(state: ReportsStateModel): ISalesReportDetails[] {
    return state.salesReports?.locations?.map((el) => ({
      ...el,
      items:
        el.items?.map((x) => ({
          month: x.month,
          sales: x.sales !== undefined ? x.sales : null,
          carCount: x.carCount !== undefined ? x.carCount : null,
          avgTicket: x.avgTicket !== undefined ? x.avgTicket : null,
          deferred: x.deferred !== undefined ? x.deferred : null
        })) ?? []
    }))
  }

  @Action(GetCallsReport)
  getCallsReport(ctx: StateContext<ReportsStateModel>, { payload }: GetCallsReport) {
    ctx.patchState({
      callsReports: undefined
    })
    return this.reportsService.getCallsReport(payload).pipe(
      tap((res) => {
        ctx.patchState({
          callsReports: res
        })
      })
    )
  }

  @Action(GetSalesReport)
  getSalesReport(ctx: StateContext<ReportsStateModel>) {
    ctx.patchState({
      salesReports: undefined
    })
    return this.reportsService.getSalesReport().pipe(
      tap((res) => {
        ctx.patchState({
          salesReports: res
        })
      })
    )
  }
}

import { ITechnicianListResponse } from '../../interfaces/technician-list.interface'
import {
  ILaborSummaryResponse,
  ITechnicianSummaryResponse
} from '../../interfaces/technician-summary.interface'

export interface TechnicianStateModel {
  laborSummaryResponse: ILaborSummaryResponse
  summaryResponse: ITechnicianSummaryResponse
  listResponse: ITechnicianListResponse
}

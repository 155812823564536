import { Permission } from '@lla-platform/users/users-data-access'
import { IAdminShopTiersItem } from '../../interfaces/admin-shop-tires.interface'
import { IAdminUserPermissionsResponse } from '../../interfaces/admin-user-permissions.interface'
import { IAdminUsersListResponse } from '../../interfaces/admin-users-list.interface'

export interface AdminStateModel {
  shopTiers: IAdminShopTiersItem[]
  usersListResponse: IAdminUsersListResponse
  userPermissions: IAdminUserPermissionsResponse
  allPermissions: Permission[]
}

import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import {
  FiltersState,
  INew_TableAction,
  INew_TableFilter,
  ISubtitleInfo,
  SetFilters
} from '@lla-platform/core/core-data-access'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { AddBookmarkDialogComponent } from '../../../../components/add-bookmark-dialog/add-bookmark-dialog.component'
import { BookmarkType } from '@lla-platform/users/users-data-access'
import { FormBuilder, UntypedFormGroup } from '@angular/forms'
import { Store } from '@ngxs/store'

@UntilDestroy()
@Component({
  selector: 'lla-table-filters-header',
  templateUrl: './table-filters-header.component.html',
  styleUrls: ['./table-filters-header.component.scss'],
  standalone: false
})
export class TableFiltersHeaderComponent implements OnInit, OnChanges {
  @Input() tableActions: INew_TableAction
  @Input() displayedColumns: string[] = []
  @Input() ignoredColumns: string[] = []
  @Input() title: string
  @Input() subtitleInfo: ISubtitleInfo
  @Input() advancedFilter = true
  @Input() bookmarkType: BookmarkType

  @Output() filterButtonClicked = new EventEmitter()
  @Output() columnsVisibilityChanged = new EventEmitter()

  showHideColumnsForm: UntypedFormGroup
  numberOfAppliedFilters = 0
  showAppliedFilters = false
  filtersList: INew_TableFilter[] = []

  constructor(
    private store: Store,
    private dialogService: MatDialog,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.showAppliedFilters = !!this.store.selectSnapshot(FiltersState.showAppliedTableFilters)
    this.filtersList = Object.values(this.tableActions.filters ?? {})?.filter(
      (el) => !this.ignoredColumns?.includes(el.key)
    )
    const formFields = Object.fromEntries(
      Object.keys(this.tableActions.filters ?? {}).map((el) => [el, this.displayedColumns.includes(el)])
    )
    this.showHideColumnsForm = this.fb.group(formFields)
    this.showHideColumnsForm.valueChanges.pipe(untilDestroyed(this)).subscribe((res) => {
      this.columnsVisibilityChanged.emit(res)
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['tableActions']?.currentValue) {
      const filters = this.tableActions?.filters ?? {}
      this.numberOfAppliedFilters = Object.values(filters).filter(
        (el) => el.items && el.items.length > 0
      ).length
    }
  }

  showAddBookmarkModal() {
    this.dialogService.open(AddBookmarkDialogComponent, {
      autoFocus: false,
      panelClass: 'normal-modal',
      data: {
        bookmarkType: this.bookmarkType
      }
    })
  }

  showAppliedFiltersChanged() {
    if (this.advancedFilter) {
      this.showAppliedFilters = !this.showAppliedFilters
      this.store.dispatch(
        new SetFilters({
          showAppliedTableFilters: this.showAppliedFilters
        })
      )
      return
    }

    this.filterButtonClicked.emit()
  }
}

import { ITimePeriod, IUrlFilters } from '@lla-platform/core/core-data-access'

export interface IITireByRotationInfo {
  sales: number
  salesFormatted: string
  count: number
  countFormatted: string
}

export interface ITireByRotationItem {
  locationId: string
  locationName: string
  rotpTires: IITireByRotationInfo
  rotfTires: IITireByRotationInfo
  rotflTires: IITireByRotationInfo
  rotcTires: IITireByRotationInfo
  totals: IITireByRotationInfo
}

export interface ITireByRotationItemExtended extends IUrlFilters {
  locationId?: string
  locationName?: string

  rotpTiresSales: number
  rotpTiresSalesFormatted: string
  rotpTiresCount: number
  rotpTiresCountFormatted: string

  rotfTiresSales: number
  rotfTiresSalesFormatted: string
  rotfTiresCount: number
  rotfTiresCountFormatted: string

  rotflTiresSales: number
  rotflTiresSalesFormatted: string
  rotflTiresCount: number
  rotflTiresCountFormatted: string

  rotcTiresSales: number
  rotcTiresSalesFormatted: string
  rotcTiresCount: number
  rotcTiresCountFormatted: string

  totalsSales: number
  totalsSalesFormatted: string
  totalsCount: number
  totalsCountFormatted: string
}

export interface ITiresByRotationResponse {
  timePeriod: ITimePeriod
  locationTireSummary: ITireByRotationItem[]
  grandTotalRotpTires: IITireByRotationInfo
  grandTotalRotfTires: IITireByRotationInfo
  grandTotalRotflTires: IITireByRotationInfo
  grandTotalRotcTires: IITireByRotationInfo
  globalTotals: IITireByRotationInfo
}

export interface ITiresByRotationExtendedResponse {
  timePeriod: ITimePeriod
  locationTireSummary: ITireByRotationItemExtended[]
  grandTotal: ITireByRotationItemExtended
}

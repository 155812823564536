<form
  [formGroup]="form"
  class="relative"
  (keydown.Enter)="singleSelect.open()"
  (keydown.arrowdown)="singleSelect.open()"
  (keypress)="searchOnPress($event)"
>
  <div (click)="singleSelect.open()">
    <div #contentSlot>
      <ng-content></ng-content>
    </div>

    <div
      *ngIf="!contentSlot.children.length"
      class="w-full md:w-max md:min-w-[244px] p-xs pl-sm rounded-sm bg-white md:hover:bg-ui-hover flex justify-between items-center cursor-pointer {{
        extraClasses
      }}"
    >
      <div class="flex-1 overflow-hidden pr-sm flex flex-col">
        <div class="text-2xs leading-2xs text-txt-secondary capitalize">{{ label }}</div>
        <div class="text-sm leading-sm truncate" *ngIf="selectedId">
          {{ selectedItemName() }}
        </div>
      </div>

      <div class="flex items-center justify-center p-base bg-primary rounded-sm text-contrast">
        <svg-icon [key]="icon" class="!text-base"></svg-icon>
      </div>
    </div>
  </div>

  <div class="absolute w-full -bottom-xs invisible {{ selectorClass }}">
    <mat-select
      #singleSelect
      formControlName="value"
      [panelWidth]="panelWidth"
      (closed)="focusOnInputId()"
    >
      <mat-option *ngIf="searchable" class="!min-h-[44px] hover:!bg-transparent">
        <ngx-mat-select-search
          formControlName="searchText"
          placeholderLabel="search"
          noEntriesFoundLabel="No items match"
        ></ngx-mat-select-search>
      </mat-option>
      <ng-content select="[slot=filter]"></ng-content>
      <ng-container *ngFor="let item of selectedItems">
        @let disableItem = getDisableItem(item.value);
        <mat-option
          class="normal-select-option"
          [value]="item.value"
          [disabled]="disableItem && selectedId !== item.value"
        >
          {{ item.label }}{{ disableItem?.reason ? ' - ' + disableItem?.reason : '' }}
        </mat-option>
      </ng-container>
    </mat-select>
  </div>
</form>

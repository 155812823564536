<div *ngIf="showTitle" class="table-filter-section-title {{ titleExtraClass }}">Locations</div>

<div class="flex flex-wrap gap-sm mb-xs" [ngClass]="{ 'mt-sm': showTitle }">
  <div
    *ngFor="let item of statesList"
    class="shop-select-item"
    [ngClass]="{
      'shop-select-active': item === selectedGroup,
      'shop-select-inactive': item !== selectedGroup
    }"
    (click)="selectedGroupChanged(item)"
  >
    {{ item }}
  </div>
</div>

<div class="flex gap-base md:gap-2xl">
  <form class="w-full md:flex-1" [formGroup]="form">
    <lla-input
      [normalInputId]="searchInputId"
      controlName="searchText"
      type="text"
      placeholder="Search"
      icon="search"
      size="smaller"
      [clearable]="true"
    ></lla-input>
  </form>

  <div class="md:flex-1">
    <lla-button
      (click)="selectAllFilterdShops()"
      class="block mt-sm"
      variant="ghost"
      size="small-shrink"
      extraBtnClass="!w-max !text-primary !pt-2xs"
    >
      {{ allFilterdShopsSelected() ? 'Unselect' : 'Select' }}
      {{ filterdShopList.length === allShopsList.length ? 'all' : 'filters' }}
    </lla-button>
  </div>
  <div class="hidden 2xl:block 2xl:flex-1"></div>
</div>

<div
  class="2xl:hidden flex flex-col md:grid grid-flow-col grid-cols-2 gap-x-2xl gap-y-sm"
  [ngStyle]="{
    'grid-template-rows': 'repeat(' + Math.ceil(this.filterdShopList.length / 2) + ', minmax(0, 1fr))'
  }"
>
  <lla-checkbox
    *ngFor="let item of filterdShopList"
    [item]="item"
    [controlName]="controlName"
  ></lla-checkbox>
</div>

<div
  class="hidden 2xl:grid grid-flow-col grid-cols-3 gap-x-2xl gap-y-sm"
  [ngStyle]="{
    'grid-template-rows': 'repeat(' + Math.ceil(this.filterdShopList.length / 3) + ', minmax(0, 1fr))'
  }"
>
  <lla-checkbox
    *ngFor="let item of filterdShopList"
    [item]="item"
    [controlName]="controlName"
  ></lla-checkbox>
</div>

import { Params } from '@angular/router'

export type MenuItemType = 'main' | 'service' | 'bookmark'

export interface IMenuRouteInfo {
  index?: number
  label: string
  icon: string
  path?: string
  queryParams?: Params
  exact?: boolean
  type?: MenuItemType
  subMenus?: IMenuRouteInfo[]
  extraInfo?: any
  hidden?: boolean
  clickFn?: () => void
}

import { IWeeklyTargetWidgetRequest } from '../../interfaces/dashboard-weekly-target.interface'
import {
  IDashboardWidgetRequest,
  IDashboardWidgetTimeRequest
} from '../../interfaces/dashboard-widget.interface'

export class GetTireWidgetInfo {
  static readonly type = '[Organizations] Get Tire Widget Info'
  constructor(public payload: IDashboardWidgetTimeRequest) {}
}

export class GetWeeklyTargetWidgetInfo {
  static readonly type = '[Organizations] Get Weekly Target Widget Info'
  constructor(public payload: IWeeklyTargetWidgetRequest) {}
}

export class GetContactsTypeWidgetInfo {
  static readonly type = '[Organizations] Get Contacts Type Widget Info'
  constructor(public payload: IDashboardWidgetTimeRequest) {}
}

export class GetArReceivablesWidgetInfo {
  static readonly type = '[Organizations] Get Ar Receivables Widget Info'
  constructor(public payload: IDashboardWidgetTimeRequest) {}
}

export class GetReceivablesWidgetInfo {
  static readonly type = '[Organizations] Get Receivables Widget Info'
  constructor(public payload: IDashboardWidgetRequest) {}
}

import { Component, inject, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { ITabsSection } from '@lla-platform/core/core-ui'
import { IUser, UserAccountState } from '@lla-platform/users/users-data-access'
import { UntilDestroy } from '@ngneat/until-destroy'
import { Store } from '@ngxs/store'
import { Observable } from 'rxjs'

@UntilDestroy()
@Component({
  selector: 'lla-profile-layout',
  templateUrl: './profile-layout.component.html',
  styleUrls: ['./profile-layout.component.scss'],
  standalone: false
})
export class ProfileLayoutComponent implements OnInit {
  currentUser$: Observable<IUser> = inject(Store).select(UserAccountState.currentUser)

  selectedRoute: string
  sections: ITabsSection[] = [
    {
      key: '/profile/edit-profile',
      title: 'Edit profile',
      icon: 'user',
      smallScreensTitle: 'Profile'
    },
    {
      key: '/profile/edit-password',
      title: 'Change password',
      icon: 'lock',
      smallScreensTitle: 'Password'
    },
    {
      key: '/profile/edit-preferences',
      title: 'Preferences',
      icon: 'bell',
      smallScreensTitle: 'Preferences'
    }
  ]

  constructor(public router: Router) {}

  ngOnInit(): void {
    this.selectedRoute = this.router.url
  }
}

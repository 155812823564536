<div
  class="min-h-[140px] w-full flex justify-between items-center bg-white rounded-sm {{ containerClass }}"
  [ngClass]="{
    'mt-2xl': variant === 'error',
    'mx-2xl !w-auto shadow-lg border border-gray-100': variant === 'error-border'
  }"
>
  <div class="p-lg md:p-2xl flex-1 flex flex-col text-txt text-sm leading-sm">
    @if (variant === 'error' || variant === 'error-border') {
      <div class="mb-lg text-sm leading-sm text-txt">
        Oops! Looks like there's a lot to load, and something went wrong.
        <br />
        <span *ngIf="!alwaysMobileView" class="hidden md:block">
          Try refreshing to get things back on track!
        </span>
      </div>
      <lla-button
        class="block !w-max"
        icon="recycle-invert"
        size="small"
        (click)="refreshClicked.emit()"
      >
        Refresh Data
      </lla-button>
    } @else {
      <div #titleSlot>
        <ng-content select="[slot=title]"></ng-content>
      </div>
      <span *ngIf="!titleSlot.children.length">{{ info?.title }}</span>
      <span *ngIf="info?.subTitle" class="block mt-sm">
        {{ info?.subTitle }}
      </span>
    }
  </div>

  <div *ngIf="!alwaysMobileView" class="hidden md:flex self-stretch flex-1 xl:flex-[2] justify-end">
    <img class="h-full min-h-[144px]" [src]="imagePath + '.svg'" alt="No Report Image" />
  </div>

  <div
    class="md:hidden self-stretch w-1/3 bg-cover"
    [ngClass]="{ 'md:!block': alwaysMobileView }"
    [ngStyle]="{ 'background-image': 'url(' + imagePath + '-mobile.svg)' }"
    alt="No Report Image"
  ></div>
</div>

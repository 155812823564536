import { IGenericObject, TierType } from '@lla-platform/core/core-data-access'
import { TargetType } from '../enums/target-type.enum'

export interface ITargetsHistoryRequest {
  locationIds?: string[]
  targetType: TargetType
}

export interface ITargetsHistoryPeriodItem {
  amount: number
  amountFormated: string
  tierType: TierType
  weekNumber: number
  period: string
  startOfWeek: Date
  endOfWeek: Date
}

export interface ITargetsHistoryItem {
  locationId: string
  locationName: string
  periods: ITargetsHistoryPeriodItem[]
}

export interface ITargetsHistoryTotalItem {
  amount: number
  amountFormated: string
  weekNumber: number
  period: string
  startOfWeek: string
  endOfWeek: string
}

export interface ITargetsHistoryGraph {
  missed: number
  tier1: number
  tier2: number
  tier3: number
  weekNumber: number
  period: string
  startOfWeek: Date
  endOfWeek: Date
}

export interface ITargetsHistoryResponse {
  locations: ITargetsHistoryItem[]
  totals: ITargetsHistoryTotalItem[]
  graphs: ITargetsHistoryGraph[]
}

export interface ITargetsHistoryInfo {
  locations: IGenericObject[]
  totals: IGenericObject
  graphs: IGenericObject[]
  allPeriods: string[]
}

<mat-form-field
  [appearance]="appearance"
  class="w-full lla-input"
  [ngClass]="{
    'lla-input-smaller': size === 'smaller',
    'lla-input-small': size === 'small',
    'lla-input-default': size === 'default',
    'lla-input-textarea': type === 'textarea',
    'lla-login-input': loginInput
  }"
>
  <mat-label
    *ngIf="label"
    class="text-txt-secondary tracking-normal text-base"
    [ngClass]="{
      'text-error': isFormControlInvalid()
    }"
  >
    <span class="inline-block lowercase first-letter:uppercase">{{ label }}</span>
  </mat-label>
  <span *ngIf="prefix" matPrefix>{{ prefix }} &nbsp;</span>

  <ng-container [ngSwitch]="type">
    <ng-container *ngSwitchCase="'select'">
      <ng-container *ngTemplateOutlet="selectInputTemplate"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'date'">
      <ng-container *ngTemplateOutlet="datePickerTemplate"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'google-places'">
      <ng-container *ngTemplateOutlet="googlePlacesInputTemplate"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'textarea'">
      <ng-container *ngTemplateOutlet="textAreaTemplate"></ng-container>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <ng-container *ngTemplateOutlet="normalInputTemplate"></ng-container>
    </ng-container>
  </ng-container>

  <mat-progress-spinner *ngIf="loading" matSuffix [diameter]="16" mode="indeterminate">
  </mat-progress-spinner>
  <ng-container matSuffix *ngIf="!loading && icon && type !== 'password'">
    @if (clearable && formControl.value && formControl.value !== '') {
      <svg-icon
        key="close"
        [color]="clearIconColor"
        [fontSize]="clearIconSize"
        (click)="formControl.reset()"
      ></svg-icon>
    } @else {
      <svg-icon
        [key]="icon"
        [color]="iconColor"
        [fontSize]="iconSize"
        (click)="iconClicked.emit($event)"
      ></svg-icon>
    }
  </ng-container>
  <svg-icon
    matSuffix
    *ngIf="!loading && type === 'password'"
    key="eye-light"
    [color]="iconColor"
    [fontSize]="iconSize"
    (click)="showPassword = !showPassword"
  ></svg-icon>

  <mat-error
    class="text-sm text-error tracking-normal mt-[3px]"
    *ngIf="showErrorMessage && isFormControlInvalid()"
  >
    {{ handleErrorMessage() }}
  </mat-error>

  <ng-template #normalInputTemplate ngProjectAs="[matInput]">
    <input
      *ngIf="!useCurrencyMask"
      [id]="normalInputId"
      [mask]="mask"
      matInput
      [type]="showPassword ? 'text' : type"
      [formControl]="formControl"
      [llaAutofocus]="autoFocus"
      [errorStateMatcher]="customErrorMatcher ? customErrorMatcher : errorMatcher"
      autocomplete="on"
      (blur)="inputFocusOut.emit()"
      (focus)="inputFocus.emit()"
      [placeholder]="placeholder"
      [readonly]="readonly"
    />
    <input
      *ngIf="useCurrencyMask"
      currencyMask
      [options]="currencyMaskOption"
      matInput
      type="text"
      [formControl]="formControl"
      [llaAutofocus]="autoFocus"
      [errorStateMatcher]="customErrorMatcher ? customErrorMatcher : errorMatcher"
      autocomplete="on"
      (blur)="inputFocusOut.emit()"
      (focus)="inputFocus.emit()"
      [placeholder]="placeholder"
      [readonly]="readonly"
    />
  </ng-template>

  <ng-template #textAreaTemplate ngProjectAs="[matInput]">
    <textarea
      [mask]="mask"
      matInput
      [formControl]="formControl"
      [llaAutofocus]="autoFocus"
      [errorStateMatcher]="customErrorMatcher ? customErrorMatcher : errorMatcher"
      autocomplete="on"
      cdkTextareaAutosize
      [cdkAutosizeMinRows]="minTextAreaRows"
      [cdkAutosizeMaxRows]="maxTextAreaRows"
      [placeholder]="placeholder"
    ></textarea>
  </ng-template>

  <ng-template #googlePlacesInputTemplate ngProjectAs="[matInput]">
    <input
      matInput
      type="text"
      [formControl]="formControl"
      [llaAutofocus]="autoFocus"
      [errorStateMatcher]="customErrorMatcher ? customErrorMatcher : errorMatcher"
      id="google-places-input"
      [placeholder]="placeholder"
    />
  </ng-template>

  <ng-template #datePickerTemplate ngProjectAs="[matDatepicker]">
    <input
      matInput
      [matDatepicker]="picker"
      [formControl]="formControl"
      [max]="maxDate"
      [min]="minDate"
      (focus)="picker.open()"
      (click)="picker.open()"
      [placeholder]="placeholder"
    />
    <mat-datepicker #picker></mat-datepicker>
  </ng-template>

  <ng-template #selectInputTemplate ngProjectAs="mat-select">
    <mat-select
      [formControl]="formControl"
      disableOptionCentering
      panelClass="lla-select-panel {{ extraSelectPanelClass }}"
      [placeholder]="placeholder"
    >
      <mat-option *ngFor="let item of items" [value]="item.value" [disabled]="item.disabled">
        {{ item.label }}
      </mat-option>
    </mat-select>
  </ng-template>
</mat-form-field>

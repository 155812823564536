import { Injectable } from '@angular/core'
import { Action, Selector, State, StateContext } from '@ngxs/store'
import { tap } from 'rxjs/operators'
import { DashboardStateModel } from './dashboard.model'
import { DashboardService } from '../../dashboard.service'
import {
  GetArReceivablesWidgetInfo,
  GetContactsTypeWidgetInfo,
  GetReceivablesWidgetInfo,
  GetTireWidgetInfo,
  GetWeeklyTargetWidgetInfo
} from './dashboard.actions'

@State<DashboardStateModel>({
  name: 'dashboards'
})
@Injectable()
export class DashboardState {
  constructor(private dashboardService: DashboardService) {}

  @Selector()
  static tireResponse(state: DashboardStateModel) {
    return state.tireResponse
  }

  @Selector()
  static weeklyTargetResponse(state: DashboardStateModel) {
    return state.weeklyTargetResponse
  }

  @Selector()
  static contactsTypeResponse(state: DashboardStateModel) {
    return state.contactsTypeResponse
  }

  @Selector()
  static arReceivablesResponse(state: DashboardStateModel) {
    return state.arReceivablesResponse
  }

  @Selector()
  static receivablesResponse(state: DashboardStateModel) {
    return state.receivablesResponse
  }

  @Action(GetTireWidgetInfo)
  getTireWidgetInfo(ctx: StateContext<DashboardStateModel>, { payload }: GetTireWidgetInfo) {
    ctx.patchState({
      tireResponse: undefined
    })
    return this.dashboardService.getTireWidgetInfo(payload).pipe(
      tap((res) => {
        ctx.patchState({
          tireResponse: res
        })
      })
    )
  }

  @Action(GetWeeklyTargetWidgetInfo)
  getWeeklyTargetWidgetInfo(
    ctx: StateContext<DashboardStateModel>,
    { payload }: GetWeeklyTargetWidgetInfo
  ) {
    ctx.patchState({
      weeklyTargetResponse: undefined
    })
    return this.dashboardService.getWeeklyTargetWidgetInfo(payload).pipe(
      tap((res) => {
        ctx.patchState({
          weeklyTargetResponse: res
        })
      })
    )
  }

  @Action(GetContactsTypeWidgetInfo)
  getContactsTypeWidgetInfo(
    ctx: StateContext<DashboardStateModel>,
    { payload }: GetContactsTypeWidgetInfo
  ) {
    ctx.patchState({
      contactsTypeResponse: undefined
    })
    return this.dashboardService.getContactsTypeWidgetInfo(payload).pipe(
      tap((res) => {
        ctx.patchState({
          contactsTypeResponse: res
        })
      })
    )
  }

  @Action(GetArReceivablesWidgetInfo)
  getArReceivablesWidgetInfo(
    ctx: StateContext<DashboardStateModel>,
    { payload }: GetArReceivablesWidgetInfo
  ) {
    ctx.patchState({
      arReceivablesResponse: undefined
    })
    return this.dashboardService.getArReceivablesWidgetInfo(payload).pipe(
      tap((res) => {
        ctx.patchState({
          arReceivablesResponse: res
        })
      })
    )
  }

  @Action(GetReceivablesWidgetInfo)
  getReceivablesWidgetInfo(
    ctx: StateContext<DashboardStateModel>,
    { payload }: GetReceivablesWidgetInfo
  ) {
    ctx.patchState({
      receivablesResponse: undefined
    })
    return this.dashboardService.getReceivablesWidgetInfo(payload).pipe(
      tap((res) => {
        ctx.patchState({
          receivablesResponse: res
        })
      })
    )
  }
}

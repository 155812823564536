import { Injectable } from '@angular/core'
import { Action, Selector, State, StateContext } from '@ngxs/store'
import { tap } from 'rxjs/operators'
import { EmployeeStateModel } from './employee.model'
import { EmployeeService } from '../employee.service'
import { DeleteEmployee, GetEmployee, GetEmployeeList, UpdateEmployee } from './employee.actions'

@State<EmployeeStateModel>({
  name: 'employee'
})
@Injectable()
export class EmployeeState {
  constructor(private employeeService: EmployeeService) {}

  @Selector()
  static employeeDetail(state: EmployeeStateModel) {
    return state.employeeDetail
  }

  @Selector()
  static listResponse(state: EmployeeStateModel) {
    return state.listResponse
  }

  @Action(GetEmployee)
  GetEmployee(ctx: StateContext<EmployeeStateModel>, { id }: GetEmployee) {
    ctx.patchState({
      employeeDetail: undefined
    })
    return this.employeeService.getEmployee(id).pipe(
      tap((res) => {
        ctx.patchState({
          employeeDetail: res
        })
      })
    )
  }

  @Action(DeleteEmployee)
  deleteEmployee(ctx: StateContext<EmployeeStateModel>, { id }: DeleteEmployee) {
    return this.employeeService.deleteEmployee(id)
  }

  @Action(UpdateEmployee)
  updateEmployee(ctx: StateContext<EmployeeStateModel>, { payload }: UpdateEmployee) {
    return this.employeeService.updateEmployee(payload)
  }

  @Action(GetEmployeeList)
  getEmployeeList(ctx: StateContext<EmployeeStateModel>, { info }: GetEmployeeList) {
    ctx.patchState({
      listResponse: undefined
    })
    return this.employeeService.getEmployeeList(info).pipe(
      tap((res) => {
        ctx.patchState({
          listResponse: res
        })
      })
    )
  }
}

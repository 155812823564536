import { IArReceivablesWidgetResponse } from '../../interfaces/dashboard-ar-receivables.interface'
import { IContactsTypeWidgetResponse } from '../../interfaces/dashboard-contacts-type.interface'
import { IReceivablesWidgetResponse } from '../../interfaces/dashboard-receivables.interface'
import { ITireWidgetResponse } from '../../interfaces/dashboard-tires.interface'
import { IWeeklyTargetWidgetResponse } from '../../interfaces/dashboard-weekly-target.interface'

export interface DashboardStateModel {
  tireResponse: ITireWidgetResponse
  weeklyTargetResponse: IWeeklyTargetWidgetResponse
  contactsTypeResponse: IContactsTypeWidgetResponse
  arReceivablesResponse: IArReceivablesWidgetResponse
  receivablesResponse: IReceivablesWidgetResponse
}

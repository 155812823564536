import { Injectable } from '@angular/core'
import {
  HttpService,
  IExportFileResponse,
  INew_TableAction,
  TableActionsService,
  TableFilterService
} from '@lla-platform/core/core-data-access'
import { DateService } from '@lla-platform/core/core-util'
import { Observable } from 'rxjs'
import { ITiresRequest, ITiresResponse, TireLevelsExplain } from '../interfaces/tires.interface'
import { TIRES_API_URL } from './tires-api-routes'
import { ITiresListResponse, IUpdateTireRequest } from '../interfaces/tires-list.interface'
import { ILostTireRequest, ILostTireResponse } from '../interfaces/lost-tires.interface'
import { ILostTiresListResponse } from '../interfaces/lost-tires-list.interface'
import { ITiresByLevelResponse } from '../interfaces/tires-by-level.interface'
import { ITiresByStockableResponse } from '../interfaces/tires-by-stockable.interface'
import { ITiresByRotationResponse } from '../interfaces/tires-by-rotation.interface'

@Injectable({
  providedIn: 'root'
})
export class TiresService {
  constructor(
    private http: HttpService,
    private dateService: DateService,
    private tableFilterService: TableFilterService,
    private tableActionsService: TableActionsService
  ) {}

  getTiresSummary(payload: ITiresRequest): Observable<ITiresResponse> {
    const { from, to } = payload
    return this.http.post(TIRES_API_URL.tiresSummary, {
      ...payload,
      from: this.dateService.momentToDateString(from),
      to: this.dateService.momentToDateString(to)
    })
  }

  exportTiresSummary(payload: ITiresRequest): Observable<IExportFileResponse> {
    const { from, to } = payload
    return this.http.post(TIRES_API_URL.exportTiresSummary, {
      ...payload,
      from: this.dateService.momentToDateString(from),
      to: this.dateService.momentToDateString(to)
    })
  }

  getTiresByLevelSummary(payload: ITiresRequest): Observable<ITiresByLevelResponse> {
    const { from, to } = payload
    return this.http.post(TIRES_API_URL.tiresByLevelSummary, {
      ...payload,
      from: this.dateService.momentToDateString(from),
      to: this.dateService.momentToDateString(to)
    })
  }

  exportTiresByLevelSummary(payload: ITiresRequest): Observable<IExportFileResponse> {
    const { from, to } = payload
    return this.http.post(TIRES_API_URL.exportTiresByLevelSummary, {
      ...payload,
      from: this.dateService.momentToDateString(from),
      to: this.dateService.momentToDateString(to)
    })
  }

  getTiresByStockableSummary(payload: ITiresRequest): Observable<ITiresByStockableResponse> {
    const { from, to } = payload
    return this.http.post(TIRES_API_URL.tiresByStockableSummary, {
      ...payload,
      from: this.dateService.momentToDateString(from),
      to: this.dateService.momentToDateString(to)
    })
  }

  exportTiresByStockableSummary(payload: ITiresRequest): Observable<IExportFileResponse> {
    const { from, to } = payload
    return this.http.post(TIRES_API_URL.exportTiresByStockableSummary, {
      ...payload,
      from: this.dateService.momentToDateString(from),
      to: this.dateService.momentToDateString(to)
    })
  }

  getTiresByRotationSummary(payload: ITiresRequest): Observable<ITiresByRotationResponse> {
    const { from, to } = payload
    return this.http.post(TIRES_API_URL.tiresByRotationSummary, {
      ...payload,
      from: this.dateService.momentToDateString(from),
      to: this.dateService.momentToDateString(to)
    })
  }

  exportTiresByRotationSummary(payload: ITiresRequest): Observable<IExportFileResponse> {
    const { from, to } = payload
    return this.http.post(TIRES_API_URL.exportTiresByRotationSummary, {
      ...payload,
      from: this.dateService.momentToDateString(from),
      to: this.dateService.momentToDateString(to)
    })
  }

  getTiresList(info: INew_TableAction): Observable<ITiresListResponse> {
    return this.http.post(TIRES_API_URL.tiresList, this.tableActionsService.createRequestPayload(info))
  }

  exportTiresList(info: INew_TableAction): Observable<IExportFileResponse> {
    return this.http.post(
      TIRES_API_URL.exportTiresList,
      this.tableActionsService.createRequestPayload(info)
    )
  }

  updateTire(itemId: string, payload: IUpdateTireRequest) {
    return this.http.post(TIRES_API_URL.updateTire(itemId), payload)
  }

  getLostTireInfo(): Observable<ILostTireResponse> {
    return this.http.get(TIRES_API_URL.lostTire)
  }

  addLostTire(payload: ILostTireRequest) {
    return this.http.post(TIRES_API_URL.lostTire, payload)
  }

  getLostTiresList(info: INew_TableAction): Observable<ILostTiresListResponse> {
    return this.http.post(
      TIRES_API_URL.lostTiresList,
      this.tableActionsService.createRequestPayload(info)
    )
  }

  exportLostTiresList(info: INew_TableAction): Observable<IExportFileResponse> {
    return this.http.post(
      TIRES_API_URL.exportLostTiresList,
      this.tableActionsService.createRequestPayload(info)
    )
  }

  getTireLevelsExplain(): Observable<TireLevelsExplain> {
    return this.http.get(TIRES_API_URL.getTireLevelsExplain)
  }
}

import { Component, OnInit } from '@angular/core'
import { NavigationEnd, Router } from '@angular/router'
import {
  SetFiltersFromUrlQueryParams,
  SetUrlQueryParamsFromState
} from '@lla-platform/core/core-data-access'
import { Store } from '@ngxs/store'
import { isEmpty } from 'lodash'
import { filter, map } from 'rxjs'
import { gtag } from 'ga-gtag'
import { IUser, UserAccountState } from '@lla-platform/users/users-data-access'
import packageJson from '../../../../package.json'

@Component({
  selector: 'lla-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: false
})
export class AppComponent implements OnInit {
  constructor(
    private router: Router,
    private store: Store
  ) {}

  ngOnInit(): void {
    console.log(`Jarvis Version: ${packageJson.version}`)

    this.router.events
      .pipe(
        filter((e) => e instanceof NavigationEnd),
        map(() => {
          return this.router.routerState.root.snapshot.queryParams
        })
      )
      .subscribe((params) => {
        const currentUser = this.store.selectSnapshot<IUser>(UserAccountState.currentUser)
        gtag('event', 'page_view', {
          page_title: window.document.title,
          page_location: window.location.href,
          email: currentUser?.email ?? 'Not Login'
        })
        if (!isEmpty(params) && params['filters']) {
          this.store.dispatch(new SetFiltersFromUrlQueryParams(params['filters']))
        } else {
          this.store.dispatch(new SetUrlQueryParamsFromState())
        }
      })
  }
}

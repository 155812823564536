import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core'
import { NavigationEnd, Router } from '@angular/router'
import {
  BookmarksState,
  BookmarkType,
  BookmarkTypeToLabel,
  DeleteBookmark,
  IBookmarkItem
} from '@lla-platform/users/users-data-access'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { Store } from '@ngxs/store'
import { filter, finalize, Observable } from 'rxjs'
import { IMenuRouteInfo } from '../../interfaces/menu.interface'
import { FiltersState } from '@lla-platform/core/core-data-access'

@UntilDestroy()
@Component({
  selector: 'lla-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss'],
  standalone: false
})
export class MainMenuComponent implements OnInit {
  @Input() mainMenuRoutes: IMenuRouteInfo[] = []
  @Input() forDesktop = true

  @Output() menuItemClicked = new EventEmitter()

  bookmarks$: Observable<IBookmarkItem[]> = inject(Store).select(BookmarksState.bookmarks)

  deletingBookmarkId?: string
  mains: IMenuRouteInfo[] = []
  services: IMenuRouteInfo[] = []
  bookmarks: IMenuRouteInfo[] = []

  expandedIndexes: Array<number | undefined> = []

  constructor(
    private store: Store,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.mainMenuRoutes = this.mainMenuRoutes.map((el, index) => ({
      ...el,
      index
    }))
    this.mains = this.mainMenuRoutes.filter((el) => el.type === 'main')
    this.services = this.mainMenuRoutes.filter((el) => el.type === 'service')
    this.expandActiveItems()

    this.bookmarks$.pipe(untilDestroyed(this)).subscribe((res) => {
      if (res) {
        this.addBookmarksMenuItems()
      }
    })

    this.router.events
      .pipe(
        filter((e) => e instanceof NavigationEnd),
        untilDestroyed(this)
      )
      .subscribe(() => {
        this.expandActiveItems()
      })
  }

  expandActiveItems() {
    const allRoutes = [...this.mainMenuRoutes, ...(this.bookmarks ?? [])]
    for (let index = 0; index < allRoutes.length; index++) {
      const element = allRoutes[index]
      if (element.subMenus?.find((el) => el.path === location.pathname)) {
        this.expandedIndexes.push(element.index)
      }
    }
  }

  changeCollapsableMenuStatus(item: IMenuRouteInfo) {
    if (!item.subMenus) {
      return
    }
    if (!this.expandedIndexes.includes(item.index)) {
      this.expandedIndexes.push(item.index)
    } else {
      if (item.type !== 'bookmark' && item.subMenus.find((el) => el.path === location.pathname)) {
        return
      }
      this.expandedIndexes = this.expandedIndexes.filter((el) => el !== item.index)
    }
  }

  isSubMenuVisible(item: IMenuRouteInfo) {
    return item.subMenus && this.expandedIndexes.includes(item.index)
  }

  addBookmarksMenuItems() {
    const bookmarkItems = this.store.selectSnapshot(BookmarksState.bookmarks)
    const listPagesPath = this.store.selectSnapshot(FiltersState.listPagesPath)
    const allBookmarks: { [key: string]: IMenuRouteInfo[] } = {}
    for (const key of Object.keys(BookmarkType)) {
      if (bookmarkItems.find((el) => el.type === key)) {
        allBookmarks[key] = bookmarkItems
          .filter((el) => el.type === key)
          .map((el) => ({
            icon: 'redo',
            label: el.name,
            type: 'bookmark',
            path: listPagesPath[el.type],
            queryParams: {
              filters: el.token
            },
            extraInfo: el.id
          }))
      }
    }

    this.bookmarks = Object.keys(allBookmarks).map((el, index) => ({
      index: this.mainMenuRoutes.length + index,
      icon: 'bookmark',
      type: 'bookmark',
      label: BookmarkTypeToLabel[el],
      subMenus: allBookmarks[el]
    }))
  }

  deleteBookmark(event: Event, id: string) {
    event.preventDefault()
    event.stopPropagation()

    if (this.deletingBookmarkId) {
      return
    }

    this.deletingBookmarkId = id
    this.store
      .dispatch(new DeleteBookmark(id))
      .pipe(
        untilDestroyed(this),
        finalize(() => (this.deletingBookmarkId = undefined))
      )
      .subscribe()
  }

  onMenuItemClick(item: IMenuRouteInfo) {
    if (!item) {
      return
    }

    if (item.path) {
      this.menuItemClicked.emit()
    }

    if (item.clickFn) {
      item.clickFn()
    }
  }
}

import { EmployeeRole } from 'employee-data-access'
import { ISchedulerTemplate, ISchedulerUser } from '../interfaces/scheduler.interface'

export const SchedulerUsers: ISchedulerUser[] = [
  {
    id: '6dce06f1-5c98-434e-9ae9-2276cd7985ea',
    firstName: 'Blake',
    lastName: 'Boyden',
    role: EmployeeRole.GeneralService,
    mainLocationId: '34b0355f-b7f6-43bb-a33f-0cdd2cf27b8f',
    mainLocationName: "Gipson's Tire Pros - Atlanta Highway",
    isAvailable: true
  },
  {
    id: '2755cc28-bcf6-471c-a85c-232e8ca902ca',
    firstName: 'Layla',
    lastName: 'Kaestner',
    role: EmployeeRole.GeneralService,
    mainLocationId: '34b0355f-b7f6-43bb-a33f-0cdd2cf27b8f',
    mainLocationName: "Gipson's Tire Pros - Atlanta Highway",
    isAvailable: true
  },
  {
    id: '2372b664-adc7-4ee2-9e82-8e3b4a044b36',
    firstName: 'David',
    lastName: 'Henderson',
    role: EmployeeRole.Manager,
    mainLocationId: '34b0355f-b7f6-43bb-a33f-0cdd2cf27b8f',
    mainLocationName: "Gipson's Tire Pros - Atlanta Highway",
    isAvailable: true
  },
  {
    id: '86a4f0bf-6202-402b-a17d-f2d435e5457d',
    firstName: 'Roger',
    lastName: 'Chavers',
    role: EmployeeRole.Technician,
    mainLocationId: '34b0355f-b7f6-43bb-a33f-0cdd2cf27b8f',
    mainLocationName: "Gipson's Tire Pros - Atlanta Highway",
    isAvailable: true
  },
  {
    id: 'c1282d60-f32b-4af9-a227-0f58fc808cee',
    firstName: 'Bryan',
    lastName: 'Jenkins',
    role: EmployeeRole.Manager,
    mainLocationId: 'f442225d-4920-4636-85ae-0309453dfc00',
    mainLocationName: "Gipson's Tire Pros - Prattville",
    isAvailable: true
  },
  {
    id: '76a1f313-db69-47f0-8a52-106f8cf90796',
    firstName: 'Latonda',
    lastName: 'Lawson',
    role: EmployeeRole.Office,
    mainLocationId: 'f442225d-4920-4636-85ae-0309453dfc00',
    mainLocationName: "Gipson's Tire Pros - Prattville",
    isAvailable: true
  },
  {
    id: '528e3e27-179d-4f8d-af82-253cc63073c9',
    firstName: 'Nathan',
    lastName: 'Osborn',
    role: EmployeeRole.Technician,
    mainLocationId: 'f442225d-4920-4636-85ae-0309453dfc00',
    mainLocationName: "Gipson's Tire Pros - Prattville",
    isAvailable: true
  },
  {
    id: 'be6e12f1-3ed6-4a3a-9041-38efed570a1c',
    firstName: 'Chris',
    lastName: 'Williamson',
    role: EmployeeRole.GeneralService,
    mainLocationId: 'f442225d-4920-4636-85ae-0309453dfc00',
    mainLocationName: "Gipson's Tire Pros - Prattville",
    isAvailable: true
  },
  {
    id: '22fb9595-5896-4c2c-b599-599e38a88859',
    firstName: 'Brian',
    lastName: 'Carter',
    role: EmployeeRole.Technician,
    mainLocationId: 'f442225d-4920-4636-85ae-0309453dfc00',
    mainLocationName: "Gipson's Tire Pros - Prattville",
    isAvailable: true
  },
  {
    id: '2d1e01aa-3217-48f4-9559-64fd8018c8fb',
    firstName: 'Shelbi',
    lastName: 'Eddelman',
    role: EmployeeRole.Office,
    mainLocationId: 'f442225d-4920-4636-85ae-0309453dfc00',
    mainLocationName: "Gipson's Tire Pros - Prattville",
    isAvailable: false,
    selectedLocationId: 'af3740d9-b21c-47b3-85af-393e974d721d',
    selectedLocationName: 'Auto Plus - Addison'
  },
  {
    id: '32fd6942-0588-4df3-8793-75e14a9554a8',
    firstName: 'Andrew',
    lastName: 'Bass',
    role: EmployeeRole.ServiceAdvisor,
    mainLocationId: 'f442225d-4920-4636-85ae-0309453dfc00',
    mainLocationName: "Gipson's Tire Pros - Prattville",
    isAvailable: true
  },
  {
    id: '5fcda204-3f31-4b73-9257-80f61db9df53',
    firstName: 'Brenton',
    lastName: 'Warr',
    role: EmployeeRole.ServiceAdvisor,
    mainLocationId: 'f442225d-4920-4636-85ae-0309453dfc00',
    mainLocationName: "Gipson's Tire Pros - Prattville",
    isAvailable: false,
    selectedLocationId: 'd3fadca7-a12d-4a77-b40f-06bfb89b7d5d',
    selectedLocationName: 'Modica Bros. Tire Center - Orange'
  },
  {
    id: '983e4a59-4cd0-4341-a7ec-b53014ed431d',
    firstName: 'Evan',
    lastName: 'Trickett',
    role: EmployeeRole.Technician,
    mainLocationId: 'f442225d-4920-4636-85ae-0309453dfc00',
    mainLocationName: "Gipson's Tire Pros - Prattville",
    isAvailable: false,
    selectedLocationId: 'af3740d9-b21c-47b3-85af-393e974d721d',
    selectedLocationName: 'Auto Plus - Addison'
  },
  {
    id: 'd69f698e-a569-40d4-867d-c4520d5e6978',
    firstName: 'Karin',
    lastName: 'Fischer',
    role: EmployeeRole.Office,
    mainLocationId: 'f442225d-4920-4636-85ae-0309453dfc00',
    mainLocationName: "Gipson's Tire Pros - Prattville",
    isAvailable: true
  },
  {
    id: '00cfad71-9acb-4989-880e-d8390b6742ed',
    firstName: 'Employee-Chasity',
    lastName: 'Lee',
    role: EmployeeRole.Office,
    mainLocationId: 'd3fadca7-a12d-4a77-b40f-06bfb89b7d5d',
    mainLocationName: 'Modica Bros. Tire Center - Orange',
    isAvailable: true
  },
  {
    id: '6797b6f4-df30-4316-a4d9-e84faf9f975c',
    firstName: 'Joe',
    lastName: 'Trudeau',
    role: EmployeeRole.GeneralService,
    mainLocationId: 'f442225d-4920-4636-85ae-0309453dfc00',
    mainLocationName: "Gipson's Tire Pros - Prattville",
    isAvailable: true
  },
  {
    id: 'fad61475-1eb1-446f-9199-fc18811553b8',
    firstName: 'Brentney',
    lastName: 'Chavers',
    role: EmployeeRole.Office,
    mainLocationId: 'f442225d-4920-4636-85ae-0309453dfc00',
    mainLocationName: "Gipson's Tire Pros - Prattville",
    isAvailable: true
  },
  {
    id: '939b897f-ad59-434b-a100-063f62884780',
    firstName: 'Ryan',
    lastName: 'Peterson',
    role: EmployeeRole.GeneralService,
    mainLocationId: 'd3fadca7-a12d-4a77-b40f-06bfb89b7d5d',
    mainLocationName: 'Modica Bros. Tire Center - Orange',
    isAvailable: true
  },
  {
    id: '89b561d8-1c50-467d-967c-5ba1540803d0',
    firstName: 'Christopher',
    lastName: 'Quilon',
    role: EmployeeRole.GeneralService,
    mainLocationId: 'd3fadca7-a12d-4a77-b40f-06bfb89b7d5d',
    mainLocationName: 'Modica Bros. Tire Center - Orange',
    isAvailable: true
  },
  {
    id: '3f0ab669-4870-4c27-a5af-6e4fbb9ffea4',
    firstName: 'John',
    lastName: 'Bartholamew',
    role: EmployeeRole.GeneralService,
    mainLocationId: 'd3fadca7-a12d-4a77-b40f-06bfb89b7d5d',
    mainLocationName: 'Modica Bros. Tire Center - Orange',
    isAvailable: true
  }
]

export const SchedulerInitialData: ISchedulerTemplate[] = [
  {
    workDayName: 'Monday',
    workDayDate: '2025-03-24T00:00:00Z',
    workDayTemplate: [
      {
        label: 'Front Desk',
        abbreviation: 'FD',
        roles: [EmployeeRole.Manager, EmployeeRole.ServiceAdvisor, EmployeeRole.Office],
        count: 4
      },
      {
        label: 'Technicians',
        abbreviation: 'Tech',
        roles: [EmployeeRole.Technician],
        count: 2
      },
      {
        label: 'General Service',
        abbreviation: 'GS',
        roles: [EmployeeRole.GeneralService],
        count: 2
      }
    ]
  },
  {
    workDayName: 'Tuesday',
    workDayDate: '2025-03-25T00:00:00Z',
    workDayTemplate: [
      {
        label: 'Front Desk',
        abbreviation: 'FD',
        roles: [EmployeeRole.Manager, EmployeeRole.ServiceAdvisor, EmployeeRole.Office],
        count: 4
      },
      {
        label: 'Technicians',
        abbreviation: 'Tech',
        roles: [EmployeeRole.Technician],
        count: 2
      },
      {
        label: 'General Service',
        abbreviation: 'GS',
        roles: [EmployeeRole.GeneralService],
        count: 2
      }
    ]
  },
  {
    workDayName: 'Wednesday',
    workDayDate: '2025-03-26T00:00:00Z',
    workDayTemplate: [
      {
        label: 'Front Desk',
        abbreviation: 'FD',
        roles: [EmployeeRole.Manager, EmployeeRole.ServiceAdvisor, EmployeeRole.Office],
        count: 4
      },
      {
        label: 'Technicians',
        abbreviation: 'Tech',
        roles: [EmployeeRole.Technician],
        count: 2
      },
      {
        label: 'General Service',
        abbreviation: 'GS',
        roles: [EmployeeRole.GeneralService],
        count: 2
      }
    ]
  },
  {
    workDayName: 'Thursday',
    workDayDate: '2025-03-27T00:00:00Z',
    workDayTemplate: [
      {
        label: 'Front Desk',
        abbreviation: 'FD',
        roles: [EmployeeRole.Manager, EmployeeRole.ServiceAdvisor, EmployeeRole.Office],
        count: 4
      },
      {
        label: 'Technicians',
        abbreviation: 'Tech',
        roles: [EmployeeRole.Technician],
        count: 2
      },
      {
        label: 'General Service',
        abbreviation: 'GS',
        roles: [EmployeeRole.GeneralService],
        count: 2
      }
    ]
  },
  {
    workDayName: 'Friday',
    workDayDate: '2025-03-28T00:00:00Z',
    workDayTemplate: [
      {
        label: 'Front Desk',
        abbreviation: 'FD',
        roles: [EmployeeRole.Manager, EmployeeRole.ServiceAdvisor, EmployeeRole.Office],
        count: 4
      },
      {
        label: 'Technicians',
        abbreviation: 'Tech',
        roles: [EmployeeRole.Technician],
        count: 2
      },
      {
        label: 'General Service',
        abbreviation: 'GS',
        roles: [EmployeeRole.GeneralService],
        count: 2
      }
    ]
  },
  {
    workDayName: 'Saturday',
    workDayDate: '2025-03-29T00:00:00Z',
    workDayTemplate: [
      {
        label: 'Front Desk',
        abbreviation: 'FD',
        roles: [EmployeeRole.Manager, EmployeeRole.ServiceAdvisor, EmployeeRole.Office],
        count: 2
      },
      {
        label: 'Technicians',
        abbreviation: 'Tech',
        roles: [EmployeeRole.Technician],
        count: 1
      },
      {
        label: 'General Service',
        abbreviation: 'GS',
        roles: [EmployeeRole.GeneralService],
        count: 1
      }
    ]
  }
]

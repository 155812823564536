import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  ViewChild
} from '@angular/core'
import { UntilDestroy } from '@ngneat/until-destroy'
import { Chart } from 'chart.js'
import { saveAs } from 'file-saver'
import { IBarChartColor, IChartInfo } from '../../interfaces/chart.interface'
import { groupBy, mean, zip } from 'lodash'
import { ISalesReportExtendedItem } from '@lla-platform/reports/reports-data-access'
import { ChartDataType } from '../../enums/chart-data-type.enum'

@UntilDestroy()
@Component({
  selector: 'lla-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.scss'],
  standalone: false
})
export class BarChartComponent implements AfterViewInit, OnChanges, OnDestroy {
  @Input() labels: string[] = []
  @Input() info: IChartInfo<ISalesReportExtendedItem>[] = []

  @ViewChild('chartCanvas') chartCanvas: ElementRef

  chartItem: Chart

  colors: IBarChartColor = {
    sales: { '2021': '#EAD4D3', '2022': '#A46D8F', '2023': '#2D203B' },
    avgTicket: { '2021': '#CAA8F5', '2022': '#592E83', '2023': '#086375' },
    carCount: { '2021': '#F4F1BB', '2022': '#9BC1BC', '2023': '#C490D1' },
    deferred: { '2021': '#1DD3B0', '2022': '#AFFC41', '2023': '#B2FF9E' }
  }

  ngAfterViewInit(): void {
    this.chartItem = new Chart(this.chartCanvas.nativeElement, {
      type: 'bar',
      data: {
        labels: this.labels,
        datasets: []
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        animation: false,
        plugins: {
          legend: {
            display: false
          },
          tooltip: {
            mode: 'point',
            callbacks: {
              label: (tooltipItem) => {
                return `${tooltipItem.dataset.label}${tooltipItem.formattedValue}`
              }
            }
          }
        },
        scales: {
          x: {
            display: true
          },
          y: {
            type: 'linear',
            display: 'auto',
            position: 'left',
            grid: {
              drawOnChartArea: false
            }
          },
          y1: {
            type: 'linear',
            display: 'auto',
            position: 'right',
            grid: {
              drawOnChartArea: false
            }
          },
          y2: {
            type: 'linear',
            display: 'auto',
            position: 'right',
            grid: {
              drawOnChartArea: false
            }
          }
        }
      }
    })
  }

  ngOnChanges(): void {
    this.updateChart()
  }

  ngOnDestroy(): void {
    this.chartItem?.destroy()
  }

  updateChart() {
    if (!this.chartItem) {
      return
    }
    this.chartItem.data.labels = this.labels
    this.chartItem.data.datasets =
      this.info && this.info.length > 0
        ? this.info
            .map((el) => {
              const dataSets: any[] = []
              const data = el.data.filter((x) => this.labels.find((label) => x.date.month === label))
              const groupData = groupBy(data, (x) => x.date.year) ?? {}

              Object.keys(groupData).forEach((year) => {
                let color: string | undefined
                switch (el.type) {
                  case ChartDataType.AvgTicket:
                    color = this.colors.avgTicket[year]
                    break

                  case ChartDataType.CarCount:
                    color = this.colors.carCount[year]
                    break

                  case ChartDataType.Deferred:
                    color = this.colors.deferred[year]
                    break

                  default:
                    color = this.colors.sales[year]
                    break
                }
                dataSets.push({
                  label: `(${year}) ${el?.type} | ${el.shopName}: ${
                    el.type !== ChartDataType.CarCount ? '$' : ''
                  }`,
                  backgroundColor: color,
                  borderColor: color,
                  data: this.labels.map((l) => {
                    const foundItem = groupData[year].find((d) => d.date.month === l)
                    return foundItem
                      ? el.type === ChartDataType.AvgTicket
                        ? foundItem.avgTicket
                        : el.type === ChartDataType.CarCount
                          ? foundItem.carCount
                          : el.type === ChartDataType.Deferred
                            ? foundItem.deferred
                            : foundItem.sales
                      : null
                  }),
                  yAxisID:
                    el.type === ChartDataType.AvgTicket
                      ? 'y1'
                      : el.type === ChartDataType.CarCount
                        ? 'y2'
                        : 'y',
                  order: 10
                })
              })

              if (Object.keys(groupData).length > 0) {
                const lineColor =
                  el.type === ChartDataType.AvgTicket
                    ? '#519872'
                    : el.type === ChartDataType.CarCount
                      ? '#CF8BA9'
                      : el.type === ChartDataType.Deferred
                        ? '#D1D646'
                        : '#B7394B'
                const zipData = zip(...dataSets.map((el) => el.data))
                dataSets.unshift({
                  label: `Average ${el?.type} | ${el.shopName}: ${
                    el.type !== ChartDataType.CarCount ? '$' : ''
                  }`,
                  data: zipData.map((el) => mean(el.filter((x) => x !== null && x !== undefined))),
                  yAxisID:
                    el.type === ChartDataType.AvgTicket
                      ? 'y1'
                      : el.type === ChartDataType.CarCount
                        ? 'y2'
                        : 'y',
                  borderColor: lineColor,
                  backgroundColor: lineColor,
                  pointBackgroundColor: lineColor,
                  pointBorderColor: lineColor,
                  type: 'line',
                  order: 1
                })
              }

              return dataSets
            })
            .flat()
        : []
    this.chartItem.update()
  }

  printImage() {
    const image = this.chartItem.toBase64Image()
    saveAs(image, 'lla-chart.png')
  }
}

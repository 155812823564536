import { INew_TableAction } from '@lla-platform/core/core-data-access'
import { IAdminShopTiersUpdate } from '../../interfaces/admin-shop-tires.interface'
import {
  IAdminUpdatePermissionsRequest,
  IBulkEditLocationsRequest,
  IBulkEditPermissionsRequest
} from '../../interfaces/admin-user-permissions.interface'

export class AdminGetShopTires {
  static readonly type = '[admin] Admin Get Shop Tires'
}

export class AdminUpdateShopTires {
  static readonly type = '[admin] Admin Update Shop Tires'
  constructor(public payload: IAdminShopTiersUpdate) {}
}

export class AdminGetUsersList {
  static readonly type = '[admin] Admin Get Users List'
  constructor(public info: INew_TableAction) {}
}

export class AdminGetUserPermissions {
  static readonly type = '[admin] Admin Get User Permissions'
  constructor(public userId: string) {}
}

export class AdminUpdateUserPermissions {
  static readonly type = '[admin] Admin Update User Permissions'
  constructor(
    public userId: string,
    public payload: IAdminUpdatePermissionsRequest
  ) {}
}

export class AdminGetAllPermissions {
  static readonly type = '[admin] Admin Get All Permissions'
}

export class AdminBulkEditLocations {
  static readonly type = '[admin] Admin Bulk Edit Locations'
  constructor(public payload: IBulkEditLocationsRequest) {}
}

export class AdminBulkEditPermissions {
  static readonly type = '[admin] Admin Bulk Edit Permissions'
  constructor(public payload: IBulkEditPermissionsRequest) {}
}
